import './App.css';
import ViewComponent from './components/ViewComponent';
import React, { useEffect } from "react";
import { isDesktop, isMobile } from 'react-device-detect';

function App() {
  // useEffect(() => {
  //   if(window.location.host.indexOf("localhost") == -1) {
  //     if(isMobile && window.location.host.indexOf("mobile") == -1) {
  //       window.location.host = "mobile.peakwoodpackers.com"
  //     }
  //     if(isDesktop && window.location.host.indexOf("mobile") > -1) {
  //       window.location.host = "peakwoodpackers.com"
  //     }
  //   }
    
  // }, [])

  return (
    <div className="main-app">
        <ViewComponent />
    </div>
  );
}

export default App;
