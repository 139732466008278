import React, { useEffect, useState } from "react";
import AddOrUpdateCustomer from "./AddOrUpdateCustomer";
import AntdTableComponent from './AntdTableComponent';
import { Button, Input, Tooltip, Popconfirm } from "antd";
import { connect } from "react-redux";
import service from "../common/service";
import { DeleteOutlined, CloudSyncOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import style from "../css/common.js";
import LoaderComponent from "./LoaderComponent";
import ModalComponent from "./ModalComponent";
import AddOrUpdateProduct from "./AddOrUpdateProduct";

const ProductComponent = (props) => {

    const [isDataChange, renderNull] = useState(false);
    const [modalOpen, setModalOpen] = useState(true);
    const [productData, setProductData] = useState([]);
    const [updateProductClicked, changeUpdateProductClick] = useState(false);
    const [updateProductData, changeUpdateProductData] = useState({});
    const productTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: true,
        scrollX: 800,
        scrollY: 300,
        pageSize: 15,
        showIndex: false,
        indexTitle: "S.No",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: 'S.NO',
                key: 'sno',
                width: '35px',
                fixed: "left",
                render: (text, object, index) =>  index + 1
              
            },
            {
                title: "PRODUCT CATEGORY",
                dataIndex: "product_category",
                nullText: "NA",
                sort: false,
                width: 200,
                showFilter: false,
                fixed: "left"
            },
            {
                title: "PRODUCT DIMENSION",
                dataIndex: "product_dimension",
                nullText: "NA",
                sort: false,
                width: 130,
                showFilter: false,
                fixed: "left"
            },
            {
                title: "PRODUCT DESCRIPTION",
                dataIndex: "product_description",
                nullText: "NA",
                sort: false,
                width: 350,
                showFilter: false,
            },
            
            {
                title: "HSN CODE",
                dataIndex: "product_hsn_code",
                nullText: "NA",
                sort: false,
                width: 100,
                showFilter: false,
            },
            {
                title: "PRICE",
                dataIndex: "product_price",
                nullText: "NA",
                sort: false,
                width: 80,
                showFilter: false,
            },
            {
                title: "OPTIONS",
                sort: false,
                width: 80,
                showFilter: false,
                fixed: "right",
                render: (text, record, index) => {
                    // console.log(text, record, index)
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Update"}>
                                <Button icon={<CloudSyncOutlined />} style={{ ...style.TableIcons, backgroundColor: "#00af80" }} onClick={e => handleUpdateProduct(text, record, index)}/>
                            </Tooltip>
                            <Tooltip placement="top" title={"Delete"}>
                                <Popconfirm
                                    placement="topRight"
                                    title="Are you sure Want to Delete?"
                                    onConfirm={e => handleDeleteProduct(text, record, index)}
                                >
                                    <Button icon={<DeleteOutlined />} style={{ ...style.TableIcons }} /> </Popconfirm>

                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }
    useEffect(() => {
        props.setProductTableData();
    }, []);

    useEffect(() => {
        let products = props.productTableData || [];
        products.forEach(product => {
            product.product_dimension = `${product.product_length} X ${product.product_width} X ${product.product_height}`
        })
        let finalData  = service.sortByKey(products, "product_category", "asc");
        setProductData(finalData);
        setModalOpen(false);
        renderNull(true)
    }, [props.productTableData])
    const addProductPopupOpen = () => {
        setModalOpen(!modalOpen);
    }
    const handleModalClose = () => {
        setModalOpen(!modalOpen);
        changeUpdateProductClick(false)
    }

    const handleDeleteProduct = (e, value, data) => {
            service.DELETE("delete_product_data", [{column: "_id", value: [e._id]}]).then(res => {
                props.setProductTableData();
            }).catch(err => err)
        
        
    }
    const handleUpdateProduct = (e, value, data) => {
        changeUpdateProductClick(true)
        setModalOpen(!modalOpen);
        changeUpdateProductData(e)
    }


    const handleSearch = (e) => {
        let value = e.target.value;
        let data = [...props.productTableData];
        let finalData = data.filter(m => {
            if(`${m.product_length} X ${m.product_width} X ${m.product_height}`.toLowerCase().indexOf(value.toLowerCase()) > -1 || m.product_category.toLowerCase().indexOf(value.toLowerCase()) > -1 || m.product_description.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                return true
            };
        }
        );
        if(value.length) {
            finalData = service.sortByKey(finalData, "product_category", "asc");
            setProductData(finalData.length ? finalData : []);
        } else {
            finalData  = service.sortByKey(props.productTableData, "product_category", "asc");
            setProductData(finalData);
        }
        
        renderNull(true);
    }

    useEffect(() => {
        renderNull(false)
    }, [productData])

    return (
        <div className="product-container" style={{width: "100%"}}>
            <div>
                {
                    <div style={style.CustomerSearch}>
                        <Button style={style.AddCustomerButton} onClick={addProductPopupOpen}> <PlusOutlined />Add Product</Button>
                        <Input placeholder="Search here..."
                            allowClear
                            addonAfter={<SearchOutlined />}
                            size="large"
                            onChange={handleSearch} />
                    </div>

                }
            </div>
            {

                productData ? (!isDataChange ? <AntdTableComponent data={productData} config={{ ...productTableConfig }} /> : null) : <LoaderComponent />
            }
            {
                modalOpen ? <ModalComponent visible={modalOpen} onClose={handleModalClose} children={<AddOrUpdateProduct isUpdate={updateProductClicked} productData={updateProductData} closePopup={handleModalClose} />} title={updateProductClicked ? "Update Product Details" : "Add Product Details" }/> : null

            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        productTableData: state.productTableData
    }
};

const mapDispatchToProps = dispatch => ({
    setProductTableData: data => dispatch(service.fetchProductData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductComponent);