import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox
} from "antd";
import service from "../common/service";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";

const LoginComponent = (props) => {
  const [form] = Form.useForm();
  const [userNotFound, setUserNotFound] = useState(false)
  const onFinish = values => {
    service.LOGIN(values.username, values.password).then(data => {
      if (data.length) {
        if (values.remember) {
          localStorage.setItem("user", data);
        }
        props.setCurrentMenu("invoice");
      }
    }).catch(err => {
      if (err) {
        setUserNotFound(true)
      }
    })
  };

  return (
    <Form name="invoice" form={form}
      layout="horizontal"
      scrollToFirstError
      initialValues={{ remember: true }}
      onFinish={onFinish}
      style={{ width: "35%", marginTop: "75px" }}
      id="login-form"
    >
      <p className="form-title">Welcome back</p>
      <p>Login to the Dashboard</p>
      { userNotFound ? <p style={{ margin: "10px 0px", color: "red" }}>Incorrect Username and Password</p> : null}
      <Form.Item
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input
          placeholder="Username"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password
          placeholder="Password"
        />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>Remember me</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          LOGIN
        </Button>
      </Form.Item>

    </Form>

  );
};

const mapStateToProps = state => {
  return {
    user: state.user
  }
};

const mapDispatchToProps = dispatch => ({
  setCurrentMenu: data => dispatch(ACTIONS.setCurrentMenu(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent);

