import React, { useEffect, useState } from "react";
import AddOrUpdateCustomer from "./AddOrUpdateCustomer";
import AntdTableComponent from './AntdTableComponent';
import { Button, Input, Tooltip, Popconfirm, Card, Dropdown, Menu, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import service from "../common/service";
import { DeleteOutlined, CloudSyncOutlined, PlusOutlined, SearchOutlined, EllipsisOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import style from "../css/common.js"
import LoaderComponent from "./LoaderComponent";
import ModalComponent from "./ModalComponent";
import state_list from "../data/state_list.json";
import { isDesktop, isMobile } from 'react-device-detect';

const { Text } = Typography;

const CustomerComponent = (props) => {

    const [isDataChange, renderNull] = useState(false);
    const [modalOpen, setModalOpen] = useState(true);
    const [customerData, setCustomerData] = useState([]);
    const [updateCustomerClicked, changeUpdateCustomerClick] = useState(false);
    const [cardData, changeCardData] = useState([]);
    const customerDesktopTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: true,
        scrollX: 1200,
        scrollY: 300,
        pageSize: 25,
        showIndex: true,
        indexTitle: "S.NO",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "CUSTOMER NAME",
                dataIndex: "customer_name",
                nullText: "NA",
                sort: false,
                width: 250,
                showFilter: false,
                fixed: 'left',
            },
            {
                title: "CUSTOMER GST NUMBER",
                dataIndex: "customer_gst_number",
                nullText: "NA",
                sort: false,
                width: 160,
                showFilter: false,
            },
            {
                title: "CUSTOMER MSME NUMBER",
                dataIndex: "customer_msme_number",
                nullText: "NA",
                sort: false,
                width: 160,
                showFilter: false,
            },
            {
                title: "CUSTOMER PHONE",
                dataIndex: "customer_phone",
                nullText: "NA",
                sort: false,
                width: 130,
                showFilter: false,
            },
            {
                title: "CUSTOMER E-MAIL",
                dataIndex: "customer_email",
                nullText: "NA",
                sort: false,
                width: 180,
                showFilter: false,
            },
            {
                title: "CUSTOMER ADDRESS",
                dataIndex: "customer_address",
                nullText: "NA",
                sort: false,
                width: 350,
                showFilter: false,
            },
            {
                title: "OPTIONS",
                sort: false,
                width: 100,
                showFilter: false,
                fixed: 'right',
                render: (text, record, index) => {
                    // console.log(text, record, index)
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Update"}>
                                <Button icon={<CloudSyncOutlined />} style={{ ...style.TableIcons, backgroundColor: "#00af80" }} onClick={e => handleUpdateCustomer(text, record, index)} />
                            </Tooltip>
                            <Tooltip placement="top" title={"Delete"}>
                                <Popconfirm
                                    placement="topRight"
                                    title="Are you sure Want to Delete?"
                                    onConfirm={e => handleDeleteCustomer(text, record, index)}
                                >
                                    <Button icon={<DeleteOutlined />} style={{ ...style.TableIcons }} /> </Popconfirm>

                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }
    const customerMobileTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: false,
        scrollX: (window.innerWidth - 50),
        scrollY: window.innerHeight,
        pageSize: 25,
        showIndex: false,
        indexTitle: "S.NO",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "CUSTOMER NAME",
                sort: false,
                width: 350,
                showFilter: false,
                render: (text, record, index) => {
                    return (
                        <div className="table-options">
                            <Tooltip placement="top" title={record.customer_name}>
                                <div style={{ fontSize: "12px", color: "#ccc" }}>{record.customer_gst_number}</div>
                                <div style={{ fontSize: "15px" }}>{record.customer_name}</div>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: "OPTIONS",
                sort: false,
                width: 10,
                showFilter: false,
                render: (text, record, index) => {
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Click to View Customer Details"}>
                                <Button style={{ backgroundColor: "transparent", border: "unset", color: "blue", padding: "unset" }} onClick={e => handleViewDetails(text, record, index)}>View Details</Button>
                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }
    useEffect(() => {
        props.setCustomerTableData();
    }, []);

    useEffect(() => {
        let customerData = props.customerTableData || [];
        customerData.forEach(customer => {
            let state_name = state_list.reduce((acc, obj) => {
                if (obj.state_code == customer.state_code) {
                    acc = obj.state_name;
                }
                return acc;
            }, "")
            let customer_address = `${customer.customer_address_line1}, ${customer.customer_address_line2 ? customer.customer_address_line2 : ""},
             ${customer.customer_address_line3 ? customer.customer_address_line3 : ""}, ${customer.city}, ${state_name} - ${customer.zip_code}`;
            customer.customer_address = service.titleCase(customer_address)
        })


        setCustomerData(props.customerTableData);
        setModalOpen(false);
        renderNull(true)
    }, [props.customerTableData])
    const addCustomerButton = () => {
        if(isMobile) {
            props.setCurrentMenu("add_customer");
        } else {
            setModalOpen(!modalOpen);
        }
        
    }
    const handleModalClose = () => {
        setModalOpen(!modalOpen);
        changeUpdateCustomerClick(false)
    }

    const handleDeleteCustomer = (e, value, data) => {
        service.DELETE("delete_customer_data", [{ column: "_id", value: [e._id] }]).then(res => {
            props.setCustomerTableData();
        }).catch(err => err)


    }

    const handleUpdateCustomer = (e, value, data) => {
        changeUpdateCustomerClick(true)
        setModalOpen(!modalOpen);
        props.updateCustomerDetails(e);
    }


    const handleSearch = (e) => {
        let value = e.target.value;
        let data = [...props.customerTableData];

        let finalData = data.filter(m => m.customer_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
        if (value.length) {
            finalData = service.sortByKey(finalData, "customer_name", "asc");
            setCustomerData(finalData.length ? finalData : []);
        } else {
            finalData = service.sortByKey(props.customerTableData, "customer_name", "asc");
            setCustomerData(finalData);
        }

        renderNull(true);
    }

    useEffect(() => {
        renderNull(false)
    }, [customerData]);

    const handleExtraMenu = (record, value) => {
        if(value.key == "update") {
            props.setCurrentMenu("update_customer");
            props.updateCustomerDetails(record);
            
        } else if (value.key == "delete") {
            handleDeleteCustomer(record);
        }
    }

    const handleViewDetails = (value, record, index) => {
        let data = customerData.filter(m => m._id == record._id);
        changeCardData(data)
    }

    const extraMenu = (m) => {
        return (
            <Menu onClick={e => handleExtraMenu(m, e)}>
                <Menu.Item key="update" icon={<CloudSyncOutlined />}>
                    <div>Update</div>
                </Menu.Item>
                <Menu.Item key="delete" icon={<DeleteOutlined />}>
                    <div>Delete</div>
                </Menu.Item>
            </Menu>
        )
    }
    const renderExtra = (m) => {
        return (
            <Dropdown overlay={extraMenu(m)} trigger={['click']} placement="bottomRight" arrow>
                <EllipsisOutlined key="ellipsis" style={{ transform: "rotate(90deg)" }} />
            </Dropdown>
        )
    }
    const renderCard = () => {
        return cardData.map((m, i) => {
            return (
                <Card
                    title={
                        <div>
                            <div>{m.customer_name}</div>
                            <div style={{ fontSize: "11px", fontWeight: 400, color: "#ccc" }}>{m.customer_gst_number}</div>
                        </div>}
                    extra={renderExtra(m)}
                    headStyle={{ padding: "0px 10px" }}
                    bodyStyle={{ padding: "10px 10px" }}
                    style={{ margin: "10px 0px" }}
                >
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={7}>
                            <Text>E-mail:-</Text>
                        </Col>
                        <Col span={12}>
                            <Text>{m.customer_email ? m.customer_email : "Nill"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={7}>
                            <Text>Phone:-</Text>
                        </Col>
                        <Col span={12}>
                            <Text>{m.customer_phone ? m.customer_phone : "Nill"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={7}>
                            <Text>MSME No:-</Text>
                        </Col>
                        <Col span={12}>
                            <Text>{m.customer_msme_number ? m.customer_msme_number : "Nill"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={7}>
                            <Text>Address:-</Text>
                        </Col>
                        <Col span={12}>
                            <Text>{m.customer_address ? m.customer_address : "Nill"}</Text>
                        </Col>
                    </Row>
                </Card>
            )
        });
    }

    const renderAddCustomer = () => {
        return (
            <Button style={style.AddCustomerButton} size="small" onClick={addCustomerButton}> <PlusOutlined />Add Customer</Button>
        )
    }

    return (
        <div className="customer-container" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <div>
                {
                    <div style={style.CustomerSearch}>
                        {

                            isDesktop ? renderAddCustomer() : null
                        }
                        <Input placeholder="Search here..."
                            allowClear
                            addonAfter={<SearchOutlined />}
                            size="large"
                            onChange={handleSearch} />
                    </div>

                }
            </div>

            {

                customerData ? (!isDataChange ? <AntdTableComponent data={customerData} config={isMobile ? customerMobileTableConfig : customerDesktopTableConfig} /> : null) : <LoaderComponent />
            }
            {

                isMobile && cardData && cardData.length ? renderCard() : null
            }
            {

                isMobile ? renderAddCustomer() : null
            }
            {
                modalOpen ? <ModalComponent visible={modalOpen} onClose={handleModalClose} children={<AddOrUpdateCustomer isUpdate={updateCustomerClicked} closePopup={handleModalClose} />} title={updateCustomerClicked ? "Update Customer Details" : "Add Customer Details"} /> : null

            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        customerTableData: state.customerTableData
    }
};

const mapDispatchToProps = dispatch => ({
    updateCustomerDetails: data => dispatch(ACTIONS.updateCustomerDetails(data)),
    setCurrentMenu: data => dispatch(ACTIONS.setCurrentMenu(data)),
    setCustomerTableData: data => dispatch(service.fetchCustomerData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerComponent);