const Types = {
    MENU_COLLAPSED: "MENU_COLLAPSED",
    MENU_CHANGE: "MENU_CHANGE",
    ADD_CUSTOMER: "ADD_CUSTOMER",
    SET_CUSTOMER_TABLE_DATA: "SET_CUSTOMER_TABLE_DATA",
    SET_PRODUCT_TABLE_DATA: "SET_PRODUCT_TABLE_DATA",
    SET_CURRENT_MENU: "SET_CURRENT_MENU",
    SET_USER_DETAILS: "SET_USER_DETAILS",
    UPDATE_CUSTOMER_DETAILS: "UPDATE_CUSTOMER_DETAILS",
    UPDATE_CUSTOMER_DETAILS_FROM: "UPDATE_CUSTOMER_DETAILS_FROM",
    UPDATE_PRODUCT_DETAILS_FROM: "UPDATE_PRODUCT_DETAILS_FROM",
};
const handleMenuOpen = data => ({
    type: Types.MENU_COLLAPSED,
    payload: data
});
const handleMenuChange = data => ({
    type: Types.MENU_CHANGE,
    payload: data
});
const addCustomerData = data => ({
    type: Types.ADD_CUSTOMER,
    payload: data
});
const setCustomerTableData = data => ({
    type: Types.SET_CUSTOMER_TABLE_DATA,
    payload: data
});
const setProductTableData = data => ({
    type: Types.SET_PRODUCT_TABLE_DATA,
    payload: data
});
const setCurrentMenu = data => ({
    type: Types.SET_CURRENT_MENU,
    payload: data
});
const setUserDetails = data => ({
    type: Types.SET_USER_DETAILS,
    payload: data
});
const updateCustomerDetails = data => ({
    type: Types.UPDATE_CUSTOMER_DETAILS,
    payload: data
});
const updateCustomerDetailsFrom = data => ({
    type: Types.UPDATE_CUSTOMER_DETAILS_FROM,
    payload: data
});
const updateProductDetailsFrom = data => ({
    type: Types.UPDATE_PRODUCT_DETAILS_FROM,
    payload: data
});

export default {
    handleMenuOpen,
    handleMenuChange,
    addCustomerData,
    setCustomerTableData,
    setProductTableData,
    setCurrentMenu,
    setUserDetails,
    updateCustomerDetails,
    updateCustomerDetailsFrom,
    updateProductDetailsFrom,
    Types
};