import React from "react";
import {  StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import tick from "../../images/check-mark.png"

const currencyFormatter = require('currency-formatter');

const styles = StyleSheet.create({
    customer_container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    top_header: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        padding: "0px 5px",
        borderBottom: "1px solid #000"
    },
    top_body: {
        display: "flex",
        width: "100%",
        "height": "250px",
        flexDirection: "column",
        padding: "5px 5px"
    },
    product: {
        display: "flex",
        width: "100%",
        flexDirection: "row"
    },
    top_portion: {
    },
    bottom_portion: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    bottom_header: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        padding: "0px 5px",
        borderBottom: "1px solid #000",
        borderTop: "1px solid #000"
    },
    bottom_body: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        padding: "0px 5px",
        borderBottom: "1px solid #000",
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #000",
    }
});

const ProductDetails = (props) => {

    let {product_details, gst_details, material_details, summary_details} = props.pdfInfo || [];
    return (
        <View style={styles.customer_container}>
            <View style={styles.top_header}>
                <Text style={{ width: 50, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>S.no</Text>
                <Text style={{ flex: 4, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Description </Text>
                <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Size </Text>
                <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>HSN CODE</Text>
                <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Price</Text>
                <Text style={{ flex: 0.5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Qty</Text>
                <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center" }}>Total</Text>
            </View>
            <View style={styles.top_body}>
                {
                    product_details.map((item, i) => {
                        return (
                            <View style={styles.product}>
                                <Text style={{ width: 50, padding: "3px 0px 3px 5px", display: "flex", textAlign: "center", }}>{i + 1}</Text>
                                <Text style={{ flex: 4, padding: "3px 0px 3px 5px", display: "flex", textAlign: "left", }}>{`${item.product_description}`}</Text>
                                <Text style={{ flex: 1.5, padding: "3px 0px 3px 5px", display: "flex", textAlign: "center", }}>{`${item.product_name}`}</Text>
                                <Text style={{ flex: 1, padding: "3px 0px 3px 5px", display: "flex", textAlign: "center", }}>{item.product_hsn_code}</Text>
                                <Text style={{ flex: 1, padding: "3px 0px 3px 5px", display: "flex", textAlign: "center", }}>{currencyFormatter.format(item.product_price, { code: 'INR' }).substr(1)}</Text>
                                <Text style={{ flex: 0.5, padding: "3px 0px 3px 5px", display: "flex", textAlign: "center", }}>{item.quantity}</Text>
                                <Text style={{ flex: 1.5, padding: "3px 5px 3px 5px", display: "flex", textAlign: "right", }}>{currencyFormatter.format(item.product_total_price, { code: 'INR' }).substr(1)}</Text>
                            </View>
                        )
                    })
                }
            </View>
            <View style={styles.bottom_portion}>
                <View style={styles.bottom_header}>
                    <Text style={{ flex: 5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Material Type</Text>
                    <Text style={{ width: 100, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Status</Text>
                    <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Gross Total</Text>
                    <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center" }}>{currencyFormatter.format(summary_details.gross_total, { code: 'INR' }).substr(1)}</Text>
                </View>
                <View>
                    <View style={styles.bottom_body}>
                        <Text style={{ flex: 5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Rubber</Text>
                        <View style={{ width: 100, padding: "3px 0px", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px solid #000" }}>
                            { material_details["rubberWood"] ? <Image style={{ width: "8px" }} src={tick} /> : null }
                        </View>
                        <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>{`CGST: ${gst_details.cgst}%`}</Text>
                        <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center" }}>{currencyFormatter.format(summary_details.cgst_value, { code: 'INR' }).substr(1)}</Text>
                    </View>
                    <View style={styles.bottom_body}>
                        <Text style={{ flex: 5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Pine Wood</Text>
                        <View style={{ width: 100, padding: "3px 0px", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px solid #000" }}>
                            { material_details["pineWood"] ? <Image style={{ width: "8px" }} src={tick} /> : null }
                        </View>
                        <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>{`CGST: ${gst_details.sgst}%`}</Text>
                        <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center" }}>{currencyFormatter.format(summary_details.sgst_value, { code: 'INR' }).substr(1)}</Text>
                    </View>
                    <View style={styles.bottom_body}>
                        <Text style={{ flex: 5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Ply Wood</Text>
                        <View style={{ width: 100, padding: "3px 0px", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "1px solid #000" }}>
                            { material_details["plyWood"] ? <Image style={{ width: "8px" }} src={tick} /> : null }
                        </View>
                        <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Total Amount</Text>
                        <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center" }}>{currencyFormatter.format(summary_details.total_amount, { code: 'INR' }).substr(1)}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.footer}>
                <Text style={{ width: 100, padding: "3px 5px", display: "flex", textAlign: "left", borderRight: "1px solid #000" }}>Amount in Words : </Text>
                <Text style={{ padding: "3px 5px", display: "flex", textAlign: "left" }}>{summary_details.amount_in_words}</Text>
            </View>
        </View>
    )
};
 
export default ProductDetails;