import React from "react";
import { PDFDownloadLink, Document, Page, PDFViewer, StyleSheet, View, Text, Image } from '@react-pdf/renderer';

import Header from "./Header";
import CustomerDetails from "./CustomerDetails";
import ProductDetails from "./ProductDetails";
import ReceiverDetails from "./ReceiverDetails";
import FooterComponent from "./FooterComponent";

const styles = StyleSheet.create({
    page: {
        "font-family": "sans-serif",
        fontSize: 9,
        padding: "25px 15px 20px 15px",
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    cancelTextCss: {
        position: "fixed",
        top: "-550px",
        left: "50px",
        // right: "0px",
        // bottom: "0px",
        zIndex: 9999,
        fontSize: "75px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "transform": "rotate(-45deg)",
        fontWeight: 900,
        letterSpacing: "14px",
        color: "#e14c4c",
        opacity: 0.4
    }
});


const PdfDownLoader = (props) => (
    <Document style={{ width: "100%", height: "100%" }}>
        <Page size="A4" style={styles.page}>
            <View style={{ border: "1px solid #000", padding: "5px 0px" }}>
            
                <Header />
                <CustomerDetails {...props}/>
                <ProductDetails {...props}/>
                <ReceiverDetails {...props}/>
                <FooterComponent {...props}/>
                {/* {props.pdfInfo && props.pdfInfo.status && props.pdfInfo.status.indexOf("Rejected") > -1? <Text style={{...styles.cancelTextCss}}>{props.pdfInfo.status}</Text> : null}
                {props.pdfInfo && props.pdfInfo.status && props.pdfInfo.status.indexOf("Draft") > -1 ? <Text style={{...styles.cancelTextCss, color: "#c7c7c7",}}>{props.pdfInfo.status}</Text> : null}
                {props.pdfInfo && props.pdfInfo.status && props.pdfInfo.status.indexOf("Approved") > -1 ? <Text style={{...styles.cancelTextCss, color: "#1cb37e",}}>{props.pdfInfo.status}</Text> : null} */}
            </View>
        </Page>
    </Document>
);

export default PdfDownLoader;