
import React, { Component } from "react";
import reactparser from "html-react-parser";
import { Select, Tooltip, Table } from "antd/dist/antd.js";
// import { css } from '@emotion/css';

import 'antd/dist/antd.css';

let { Option } = Select;
window.currentTableData = [];
export default class AntDesignTable extends Component {
  _baseClass = "antdtable_component";

  columns = [];
  data = [];
  
  constructor(props, context) {
    super(props, context);
    this.data = props.data ? props.data : props.orginalData;
    this.state = {
      selectedRowKeys: Array.isArray(this.getDefaultSelectedRowKeys()) ? this.getDefaultSelectedRowKeys() : [],
      pageSize: 10,
      currentTableData: [],
      currentPageNumber: 1,
      filters : [],
      data: props.data ? props.data : props.orginalData,
      isIndexGenerate: true,
    };
  }
  
generateFilter = (item, nullText) => {
  let filters = [];
  // let filterData = this.state.data;
  this.state.data.forEach( val => {
    let tempFilterObj;
    if(filters.length == 0){
      tempFilterObj = JSON.stringify({text: val[item] ? val[item] : nullText, value: val[item] ? val[item] : nullText});
      filters.push(tempFilterObj);
    } else {
      tempFilterObj = JSON.stringify({text: val[item] ? val[item] : nullText, value: val[item] ? val[item] : nullText});
      if(!filters.includes(tempFilterObj)){
        filters.push(tempFilterObj);
      }
    }
  });
  return filters.map( item => {
    return JSON.parse(item)
  });
}

handlePageSize = (value) => {
  this.setState({
    pageSize: value
  });
}

handleChange = (pagination, filters, sorter, extra) => {
  this.setState({
    currentTableData: extra.currentDataSource,
    currentPageNumber: pagination.current,
    pageSize: pagination.pageSize
  });
}


replaceToDataValue(column, obj) {
  return obj[column]
}

componentWillMount(){
   
  this.createColumn(this.props.config.columns);
}

generateIndex = () => {
  if(this.props.config.showIndex){
    let obj = {};
    if(this.props.config.columns[0]["fixed"]) {
      obj["fixed"] = this.props.config.columns[0]["fixed"];
    }
    this.props.config.columns.splice(0, 0, {
      title: this.props.config.indexTitle ? this.props.config.indexTitle:"#",
      dataIndex: "indexNumber",
      width: 50,
      align: "center",
      fixed: "left",
      ...obj
    });
    this.setState({isIndexGenerate : false});
  }
}

createHandlePageSize = () => {
  let optionArray = [];
  let pageSize = 10;
  let remainder = (this.props.config.pageSize ? this.props.config.pageSize % pageSize : 0);
  if(this.props.config.pageSize){
    optionArray.push(<Option value={this.props.config.pageSize}>{'Show ' + (this.props.config.pageSize)}</Option>)
  }
  for(let i = (this.props.config.pageSize ? this.props.config.pageSize + 1: 1 ); i < (this.state.currentTableData.length ? this.state.currentTableData.length : this.data.length)+1; i++){
      if(i % (pageSize) == remainder){
        optionArray.push(<Option value={i}>{'Show ' + (i)}</Option>)
      }
  }
  let content = <Select defaultValue={'Show ' + (this.state.pageSize ? this.state.pageSize : this.props.config.pageSize )} onChange={this.handlePageSize}>{optionArray}</Select>
  return content;
}

generateTooltip = (content, value, obj, isAdvancedTooltip) => {

  let arrayElement = [];
  let rows = content && content.rows && Array.isArray(content.rows) ? content.rows  : [];  
  if(isAdvancedTooltip ) {
      rows.forEach((item, index) => {
        let value = this.replaceToDataValue((item.value && item.value) || "", obj || {});
        let parsedValue = value ? reactparser(value) : " "
        let color = this.replaceToDataValue((item.style && item.style.color && item.style.color) || "", obj || {});
        item.color = color ? color : item.color;

        arrayElement.push(
          <div className={"tooltip-row-"+index}>
              <div className={"tooltip-label"} style={item.style}>{parsedValue}</div>
          </div>
        )
      })
  } else {
    arrayElement.push(
      <div className={"tooltip-row"}>
              <div className={"tooltip-label"}>{value}</div>
          </div>
    )
  }
  

  return(
    <div className="tooltip-content">
      {arrayElement}
    </div>
  )
}

createRangeIndicator = (element) => {
  element.render = (metric_value, obj) => {
    let barStyle = {
      width: (100 / element.rangeSliderConfig.customRange && element.rangeSliderConfig.customRange.length ? element.rangeSliderConfig.customRange.length : 10) + "%",
      "min-height": "12px",
      "max-height": "13px",
      "border-radius": "5px",
    }
    let barContainer = {
      width: "100%",
      height: "15%",
      display:'flex',
      'max-height': '13px',
      'min-height': '12px',
    }

    let bars = [];
    let bgColors = [
      "#f74040",
      "#e85858",
      "#ff7f00",
      "#f79b40",
      "#f7d240",
      "#f5d969",
      "#3d961b",
      "#2a800a",
      "#1e6985",
      "#0a5470"
    ];
    let rangeValue = 0;
    let suffix = this.replaceToDataValue((element.suffix && element.suffix) || "", obj || {});
    let prefix = this.replaceToDataValue((element.prefix && element.prefix) || "", obj || {});
    let valueMask = metric_value;
    
    let targetMetric = obj[element.rangeSliderConfig.targetMetric];
    let targetSuffix = this.replaceToDataValue((element.rangeSliderConfig.suffix && element.rangeSliderConfig.suffix) || "", obj || {});
    let targetPrefix = this.replaceToDataValue((element.rangeSliderConfig.prefix && element.rangeSliderConfig.prefix) || "", obj || {});
    let targetMask = targetMetric;

    

    if(element.rangeSliderConfig.hasOwnProperty("customRange")) {
      let customColors = element.rangeSliderConfig.customColors ? element.rangeSliderConfig.customColors : bgColors
      let customRange = element.rangeSliderConfig.customRange;
      let rangeIndex = -1;
      let targetIndex = -1;
      let targetValue = undefined;
      for(let i = 0; i < customRange.length; i++) {
        let labelColor = this.replaceToDataValue(
          (customRange[i]["color"] && customRange[i]["color"]) || "",
          obj || {}
        );
        let color = this.replaceToDataValue(
          (customRange[i]["barColor"] && customRange[i]["barColor"]) || "",
          obj || {}
        )
        let bgStyle = {background: color ? color : customColors[i]};
        let singleLineValue = Math.abs((customRange[i]["max"] - customRange[i]["min"]) / 10)
        if(metric_value >= customRange[i]["min"] && metric_value <= customRange[i]["max"]) {
          rangeIndex = i;
          rangeValue = (((metric_value - customRange[i]["min"]) / singleLineValue) * 10).toFixed(2);
        }

        if(targetMetric >= customRange[i]["min"] && targetMetric <= customRange[i]["max"]) {
          targetIndex = i;
          targetValue = (((targetMetric - customRange[i]["min"]) / singleLineValue) * 10).toFixed(2);
        }
        bars.push(
        
        <div className="range-indicator" key={i} style={{...barStyle, ...bgStyle}}>
          { rangeIndex == i ? <div class="range-indicator-icon metric-value" style={{ width: "100%",  height: 0, position: "relative", top: "-25px"}}>
          
              <div className="indicator-value-label" style={{height: 0, width: "auto", fontSize: "12px", fontWeight: "700", position: "relative", left: (rangeValue - 1) + "%", top: "-20px"}}>{valueMask}</div>
              { element.rangeSliderConfig.tooltip ? <Tooltip overlayClassName={element.rangeSliderConfig.tooltipCustomClass ? element.rangeSliderConfig.tooltipCustomClass : ""} overlayStyle={element.rangeSliderConfig.tooltipStyle ? element.rangeSliderConfig.tooltipStyle : {}} title={this.generateTooltip(element.rangeSliderConfig.tooltipContent, metric_value, obj, element.rangeSliderConfig.tooltipContent ? true : false)}>
                <svg style={{height: "55px", width: "18px", position: "relative", left: (rangeValue - 1) + "%"}}>
                  <rect width="10" height="20" style={{fill: color ? color : customColors[i], "stroke-width": 0}} />
                  <rect width="1" height="50" x="4" style={{"fill" :"#8f8f8f", "stroke-width":0}} />
                  <rect width="3" height="6" x="3" y="50" style={{"fill": color ? color : customColors[i], "stroke-width": 0}} > 
                  </rect>
                </svg>
              </Tooltip> : <svg style={{height: "55px", width: "18px", position: "relative", left: (rangeValue - 1) + "%"}}>
                  <rect width="10" height="20" style={{fill: color ? color : customColors[i], "stroke-width": 0}} />
                  <rect width="1" height="50" x="4" style={{"fill" :"#8f8f8f", "stroke-width":0}} />
                  <rect width="3" height="6" x="3" y="50" style={{"fill": color ? color : customColors[i], "stroke-width": 0}} > 
                  </rect>
                </svg>}
            </div> : null}
          { element.rangeSliderConfig.hasOwnProperty("targetMetric") &&  targetValue &&  targetIndex == i ? <div class="range-indicator-icon target-value" style={{ width: "100%",  height: 0, position: "relative", top: "-25px"}}>
          <div className="indicator-value-label" style={{height: 0, width: "auto", fontSize: "10px", position: "relative", left: (targetValue - 1) + "%", top: "65px"}}>{targetMask}</div>
              <svg style={{height: "100px", width: "18px", position: "relative", left: (targetValue - 1) + "%"}}>
                <rect width="1" height="40" x="4" y="25" style={{"fill" :"#8f8f8f", "stroke-width":0}} />
                <rect width="3" height="6" x="3" y="60" style={{"fill": "#303030", "stroke-width": 0}} > 
                </rect> 
              </svg>
            </div> : null}
          <div className="range-inicator-label" style={{ display: "flex", "justify-content": "space-between"}}>
            {element.rangeSliderConfig.showMinLabel ? <div style={{color: labelColor ? labelColor : "#000", fontSize: "10px", fontWeight: "700", marginLeft: "5px"}}>{customRange[i]["min"]}</div> : ""}
            {element.rangeSliderConfig.showMaxLabel ? <div style={{color: labelColor ? labelColor : "#000", fontSize: "10px", fontWeight: "700", marginRight: "5px"}}>{customRange[i]["max"]}</div> : ""}
          </div>
        </div>
        )
      }

      

    } else {
      let rangeIndex = -1;
      let targetIndex = -1;
      rangeValue = metric_value;
      let targetValue = obj[element.targetMetric]
      for (let i = 0; i < 10; i++) {
        let bgStyle = { background: bgColors[i] };
        let singleLineValue = Math.abs((((i * 10) + 10) - i*10) / 10);

        if(metric_value >= i*10 && metric_value <= (i * 10) + 10) {
          rangeIndex = i;
          rangeValue = (((metric_value - i*10) / singleLineValue) * 10).toFixed(2);
        };

        if(metric_value >= i*10 && metric_value <= (i * 10) + 10) {
          targetIndex = i;
          targetValue = (((targetMetric - i*10) / singleLineValue) * 10).toFixed(2);
        }

        bars.push(
          <div className="range-indicator" key={i} style={{ ...barStyle, ...bgStyle }}>
            {rangeIndex == i ? <div class="range-indicator-icon metric-value" style={{ width: "100%", height: 0, position: "relative", top: "-25px" }}>
              
                <div className="indicator-value-label" style={{ height: 0, width: "auto", fontSize: "12px", fontWeight: "700", position: "relative", left: (rangeValue - 1) + "%", top: "-20px" }}>{valueMask}</div>
                { element.rangeSliderConfig.tooltip ? <Tooltip title={this.generateTooltip(element.rangeSliderConfig.tooltipContent, metric_value, obj)}>
                  <svg style={{ height: "55px", width: "18px", position: "relative", left: (rangeValue - 1) + "%" }}>
                    <rect width="10" height="20" style={{ fill: bgColors[i], "stroke-width": 0 }} />
                    <rect width="1" height="45" x="4" style={{ "fill": "#8f8f8f", "stroke-width": 0 }} />
                    <rect width="3" height="6" x="3" y="45" style={{ "fill": bgColors[i], "stroke-width": 0 }} >
                    </rect>
                  </svg>
              </Tooltip> :   <svg style={{ height: "55px", width: "18px", position: "relative", left: (rangeValue - 1) + "%" }}>
                    <rect width="10" height="20" style={{ fill: bgColors[i], "stroke-width": 0 }} />
                    <rect width="1" height="45" x="4" style={{ "fill": "#8f8f8f", "stroke-width": 0 }} />
                    <rect width="3" height="6" x="3" y="45" style={{ "fill": bgColors[i], "stroke-width": 0 }} >
                    </rect>
                  </svg>}
            </div> : null}
            {element.rangeSliderConfig.hasOwnProperty("targetMetric") && targetValue && targetIndex == i ? <div class="range-indicator-icon target-value" style={{ width: "100%", height: 0, position: "relative", top: "-25px" }}>
              <div className="indicator-value-label" style={{ height: 0, width: "auto", fontSize: "10px", position: "relative", left: (targetValue - 1) + "%", top: "65px" }}>{targetMask}</div>
              <svg style={{ height: "100px", width: "18px", position: "relative", left: (targetValue - 1) + "%" }}>
                <rect width="1" height="40" x="4" y="25" style={{ "fill": "#8f8f8f", "stroke-width": 0 }} />
                <rect width="3" height="6" x="3" y="60" style={{ "fill": "#303030", "stroke-width": 0 }} >
                </rect>
              </svg>
            </div> : null}
            <div className="range-inicator-label" style={{display: "flex", "justify-content": "space-between" }}>
              {<div style={{ fontSize: "10px", fontWeight: "700", marginLeft: "5px" }}>{i * 10}</div>}
            </div>
          </div>
          )
      }
    }
    
    return (
      (<div class={'range-indicator'} style={{...{width: "100%"}, ...(element.style ?  element.style : {})}}>
        
        <div class='bars-content' style={barContainer}>
          {element.rangeSliderConfig.leftLabel ? <div className="left-label">{element.rangeSliderConfig.leftLabel}</div> : null}
          <div className="bars" style={{width: "100%", display: "flex"}}>{bars}</div>
          {element.rangeSliderConfig.rightLabel ? <div className="right-label">{element.rangeSliderConfig.rightLabel}</div> : null}
        </div>
      </div>)
    )
  }
}

  createPercentageBar = (element) => {
    element.render = (metric_value, obj, c) => {

      if(metric_value) {
        let bars = [];
      let customRange = element.percentageBarConfig.customRange;
      let targetIndex;
      let bgColor;
      let barLabel;
      let defaultColor = [
        "#f74040",
        "#e85858",
        "#ff7f00",
        "#f79b40",
        "#f7d240",
        "#f5d969",
        "#3d961b",
        "#2a800a",
        "#1e6985",
        "#0a5470"
      ];

      let defaultRange = [];

      for(let i = 0; i< 10; i++) {
        defaultRange.push({
          min: i * 10,
          max: (i * 10) + 10
        })
      }

      let range = Array.isArray(element.percentageBarConfig.customRange) && element.percentageBarConfig.customRange.length ? element.percentageBarConfig.customRange  : defaultRange;

      (range).forEach((n, i) => {
        let min = n["min"], max = n["max"];
        if (metric_value <= max && metric_value >= min) {
          barLabel = this.replaceToDataValue(
            (n.barLabel && n.barLabel) || "",
            obj || {}
          );
          bgColor = this.replaceToDataValue(
            (n.barColor && n.barColor) || "",
            obj || {}
          );
          bgColor = bgColor ? bgColor : defaultColor[i]
          targetIndex = i
        }
      })
      for (let i = 0; i < range.length; i++) {

        if (i <= targetIndex) {
          bars.push(
            <div className="percentage-indicator-bar" style={{ "width": "16px", "height": "50px", "background": bgColor, "border-radius": "3px", "margin-left": (i % 2 == 0 && i != 0) ? "15px" : "4px" }}></div>)
        } else {
          bars.push(
            <div className="percentage-indicator-bar" style={{ "width": "16px", "height": "50px", "background": "#ddd", "border-radius": "3px", "margin-left": (i % 2 == 0 && i != 0) ? "15px" : "4px" }}></div>)
        }
      }
      return (<div class='percentage-indicator' style={{ 'display':'flex','width': '500px' }}>
        <div className="bars" style={{ width: "100%", display: "flex" }}>{bars}</div>
        <div  style={{ 'margin-left': '-20%' }}>
          {barLabel ? barLabel : ''}
        </div>
      </div>)
      } else {
        if(element.hasOwnProperty("nullText")) {
          return <span className={"null-text"}>{element.nullText}</span>
        } else {
          return <span className={"null-text"}>--</span>
        }
      }
      
    }
  }


  createBar = (element) => {
    element.render = (value, obj, c) => {
    
      let barWidth = value;
      let barColor = this.replaceToDataValue((element.barColor && element.barColor) || "#034ca0", obj || {});
      
      return (
        <div className="antd-progress-bar">
          <div className="antd-progress-bar-outer" style={{ ...{ "width": "100%" }, ...(element.outerBarStyle ? element.outerBarStyle : {}) }}>
            <div className="antd-progress-bar-inner" style={{ ...(element.innerBarStyle ? element.innerBarStyle : {}), ...{ "width": barWidth + "%", "background": barColor } }}></div>
          </div>
        </div>
        )
    }
  }

  createLegend = () =>{

    let tableLegend = []
    let config = this.props.config.legendConfig && Array.isArray(this.props.config.legendConfig) ? this.props.config.legendConfig : [];
    let content = config.map((item, index) => {
      if(item.color && Array.isArray(item.color)) {
        return (
          <div key={index} className="antd-legend" style={{display: "flex", "flexDirection": "column", alignItems: "center" }}>
          <div style={{display: "flex", justifyContent: "space-around", width: "50px"}}>
              {(item.color || []).map((col, i) => {
                return (
                  
                    <div className="antd-legend-bar-content" key={i} style={{ display: "flex" }}>
                    <div className="antd-legend-bar" style={{ width: "6px", height: "25px", "margin": "0px 2px", background: col }}></div>
                    {item.hasDoubleBar ? <div className="antd-legend-bar" style={{ width: "6px", height: "25px", "margin": "0px 2px", background: col }}></div> : null}
                    </div>
                )
              })}
            </div>
            <div className="antd-legend-label">{item.label}</div>
         </div>
        )
      } else {
        return(
          <div key={index} className="antd-legend">
            <div className="antd-legend-bar-content">
                <div className="antd-legend-bar" style={{width: "6px", height: "25px", background: item.color}}></div>
            </div>
            <div className="antd-legend-label">{item.label}</div>
          </div>
        )
      }
    })

   return (<div className = 'table-legend-content' style = {{"display":"flex"}}>{content}</div>)
  }
 

  

createColumn = columns => {

  if(this.state.isIndexGenerate && this.props.config.showIndex && !(this.props.config.columns[0].dataIndex == "indexNumber")){
    this.generateIndex();
  }

  columns.forEach(element => {
    
    
    if(element.sort == true){
      element.defaultSortOrder = element.defaultSort;
      element.sorter = (a, b)  => {
        if (b[element.dataIndex] < a[element.dataIndex])
          return -1;
        if ( b[element.dataIndex] > a[element.dataIndex])
          return 1;
        return 0;
        ;
      };
      element.sortDirections = ['descend', 'ascend']
    }

    if(element.showFilter == true){
      element.filters = this.generateFilter(element.dataIndex, element.nullText);
      //filteredValue: filteredInfo.name || null,
      //element.filteredValue = filteredInfo[element.dataIndex] || null;
      element.onFilter = (value, record) => {
        if(record[element.dataIndex] == value){
          return record;
        }
        if(element.nullText == value && (record[element.dataIndex] == undefined || record[element.dataIndex] == null )){
          return record;
        }
        
      };
    }

    if(element.dataIndex == "indexNumber"){
      element.render = (text, record, index) => (this.state.currentPageNumber - 1) * (this.state.pageSize ? this.state.pageSize : this.props.config.pageSize) + (index + 1 )
    }

    if(element.dynamicHeader){
      let fromMetric = this.replaceToDataValue(
        element.dynamicHeader.fromMetric || "",
        this.data[0] || {}
      );

      element.title = fromMetric ? fromMetric : element.title;
    }

    if(element.hasLink == true){
      element.render = (text, record, index ) => {
        let reportColumnLink = this.replaceToDataValue(
          element.configLink.reportColumnLink || "",
          record || {}
        );
        return text ? <a href={reportColumnLink ? reportColumnLink : null} onClick={(e)=>{ }}>{text}</a> : null
      }
    }
    if(element.nullText){
      element.render = (text) => null || undefined || text == undefined || text == null ? element.nullText : text;
     
    }

    if(element.color){
      element.render = (text, record, index) => {
        let columnColor = this.replaceToDataValue(
          element.color || "",
          record || {}
        );
        return <span style={{color: columnColor}}>{text}</span>
      }
    }

    if(element.hasImage == true){
      element.render = (text, record, index) =>
      {
        let imgUrl = this.replaceToDataValue(
          (element.image.src) || "",
          record || {}
        );
        let backgroundColor = this.replaceToDataValue(
          (element.image.style && element.image.style.backgroundColor) || "",
          record || {}
        );
        let color = this.replaceToDataValue(
          (element.image.style && element.image.style.color) || "",
          record || {}
        );

      let content = (
        <div className={this._baseClass + '-render'}>
            { text ? ( element.image.src && ((!element.image.suffix && element.image.prefix) || ((!element.image.suffix && !element.image.prefix )) ) ? <img src={imgUrl} width={element.image.width ? element.image.width : null} height={element.image.height ? element.image.height : null} style={element.image.imageStyle ? element.image.imageStyle : null} /> : null) : null}
            {text ? <span>{text}</span>: element.nullText ? element.nullText : null}
            {text ? ( element.image.src && (element.image.suffix && !element.image.prefix ) ? <img src={imgUrl} width={element.image.width ? element.image.width : null} height={element.image.height ? element.image.height : null} style={element.image.imageStyle ? element.image.imageStyle : null} /> : null ) : null}
        </div>
      );

      return  content;
        
    }
  }

  if(element.hasOwnProperty("prefix") || element.hasOwnProperty("suffix") || element.hasOwnProperty("mask") || element.hasOwnProperty("precision") ) {
    element.render = (text, record) => {
      let valueMask = text;

    return <span>{valueMask}</span>
    }

    
    
  }

  if(element.hasOwnProperty("hasRangeSlider") && element.hasOwnProperty("rangeSliderConfig")) {
    this.createRangeIndicator(element)
  }

  if(element.hasOwnProperty("hasPercentageBar") && element.hasOwnProperty("percentageBarConfig")) {
    this.createPercentageBar(element)
  }

  if(element.hasOwnProperty("children")) {
    if(Array.isArray(element.children) && element.children.length) {
      this.createColumn(element.children)
    }
  }

  if(element.hasOwnProperty("hasBar") && element.hasBar == true) {
    this.createBar(element)
  }


  });
}


disableCheckBox = (record) => {

  let disableRowsSelection = [];
  if( typeof this.props.config.disableRowsSelection == 'string') {

    if(this.props.config.disableRowsSelection.includes("#{")) {
      let key = this.props.config.disableRowsSelection.trim().slice(2, this.props.config.disableRowsSelection.length - 1)
      if(Array.isArray(this.data[0][key])) {
        disableRowsSelection =  [...this.data[0][key]] ;
      }
    } else {
      if(Array.isArray(window[this.props.config.disableRowsSelection])) {
        disableRowsSelection = [...window[this.props.config.disableRowsSelection]]
      }
    }
  } else {
    if(Array.isArray(this.props.config.disableRowsSelection)) {
      disableRowsSelection = this.props.config.disableRowsSelection;
    }
  }
  disableRowsSelection = Array.isArray(disableRowsSelection) ? disableRowsSelection : [];
  return {
    disabled: (disableRowsSelection || []).includes(record[this.props.config.promptTarget]), // Column configuration not to be checked
    name: record.schoolname ,
  }
}

getDefaultSelectedRowKeys = () => {
  let selectedRowKeys = [];

  if( typeof this.props.config.selectedRowKeys == 'string') {

      if(this.props.config.selectedRowKeys.includes("#{")) {
        let key = this.props.config.selectedRowKeys.trim().slice(2, this.props.config.selectedRowKeys.length - 1)
        if(Array.isArray(this.data[0][key])) {
          selectedRowKeys =  [...this.data[0][key]] ;
        }
      } else {
        if(Array.isArray(window[this.props.config.selectedRowKeys])) {
          selectedRowKeys = [...window[this.props.config.selectedRowKeys]]
        }
      }
    } else {
      if(Array.isArray(this.props.config.selectedRowKeys)) {
        selectedRowKeys = [...this.props.config.selectedRowKeys]
      }
    }
    return selectedRowKeys;
}

renderExpandIcon = (props) => {
// console.log(props)

  let iconStyle = {
    border: "solid #ccc",
    "border-width": "0 3px 3px 0",
    display: "inline-block",
    padding: "3px",
    position: "unset"
  }

  if (props.isActive) {
    iconStyle.transform = "rotate(45deg)"
    return (
      <div class="arrow-down" onClick={e => {
        props.onExpand(props.record, e);
      }}>
        <i class="arrow-down-icon" style={iconStyle}></i>
      </div>
    );
  } else {
    iconStyle.transform = "rotate(-135deg)"
    return (
      <div class="arrow-down" onClick={e => {
        props.onExpand(props.record, e);
      }}>
        <i class="arrow-down-icon" style={iconStyle}></i>
      </div>
    );
  }
}

expandedRowRender = (record, index, indent, isExpanded) => {
  // console.log(record, index, indent, isExpanded)
  let subTableConfig = this.props.config.subTableConfig;
  this.createColumn(subTableConfig);
  return <Table columns={subTableConfig} dataSource={this.props.data} pagination={false} indentSize={0} showHeader={false}/>
}

render() {
  const style = this.props.config.style || {};
  const rowStyle = this.props.config.dataStyle || {};
  let selectedRowKeys = [];
  if(this.props.config.selectedRowKeys){
    selectedRowKeys = [...this.state.selectedRowKeys];
  }
  
  const rowSelection = {
    columnWidth: this.props.config.selectionColumnWidth ? this.props.config.selectionColumnWidth : "10px",
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if(this.props.config.multipleRowSelection == false) {
        selectedRowKeys = selectedRowKeys.slice(selectedRowKeys.length - 1)
      }
      this.setState({
        selectedRowKeys: [...selectedRowKeys]
      })
      if(this.props.config.promptTarget && this.props.config.promptValue) {
        let promptTarget = this.replaceToDataValue(
          this.props.config.promptTarget || "",
          selectedRows[0] || {}
        )

        let promptValue = this.replaceToDataValue(
          this.props.config.promptValue || "",
          selectedRows[0] || {}
        )

        // window.setElasticPrompt(selectedRows, promptTarget ? promptTarget : this.props.config.promptTarget, this.props.data, promptValue, selectedRowKeys)
      }
      
    },
    // getCheckboxProps: record => (this.disableCheckBox(record)),
  };
  return (
    <div>
      {this.props.config.showTableLegend &&  this.props.config.legendConfig ? <div class = "table-legend">{this.createLegend()}</div> : null }
      <Table 
        expandedRowRender={this.props.config.subTableConfig ? this.expandedRowRender : null}
        expandIcon = {this.renderExpandIcon}
        rowSelection={this.props.config.rowSelection ? rowSelection : null}
        columns={this.props.config.columns} 
        dataSource={this.data} 
        size={this.props.config.tableSize} 
        scroll={{ x: this.props.config.scroll && this.props.config.scrollX ? 
          this.props.config.scrollX : null, y: this.props.config.scroll && this.props.config.scrollY ? this.props.config.scrollY : null }} 
        pagination={this.props.config.showPagination == false ? false : { pageSize: this.state.pageSize ? this.state.pageSize : this.props.config.pageSize }} 
        onChange={this.handleChange}
        className={style}	
        rowClassName={rowStyle} 
        onRow={ record => ({ onClick: (e) => { }})}  
      /> 
      {this.props.config.pageOptions == false || this.createHandlePageSize()}
    </div> 
    )
  }
}
