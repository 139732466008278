
export default {
    MenuContainer: {
        position: "fixed",
        top: "50px",
        "zIndex": "99"
    },
    MenuContent: {
        width: "max-content",
        background: "#2f4f4f",
    },
    MenuHeader: {
        width: "100%",
        display: "flex",
        padding: "10px"
    },
    MenuLogo: {
        width: "30px"
    }, 
    MenuHeaderTitle: {
        color: "#fff",
        opacity: 0.7,
        padding: "0px 10px",
        fontSize: "18px"
    },
    MenuList: {
        margin: "unset",
        background: "#2f4f4f",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    ConvertToUpperCase: {
        "textTransform":"uppercase"
    },
    ConvertToCapitalCase: {
        "textTransform":"capitalize"
    },
    TableIconsContainer: {
        display: "flex",
        "justifyContent": "space-around"
    },
    TableIcons: {
        width: "35px",
        height: "25px",
        background: "#e44d26",
        border: "unset",
        color: "#fff",
    },
    CustomerSearch: {
        display: "flex",
        "flexDirection": "column",
        justifyContent: "flex-end",
        alignItems: "flex-end"
    },
    AddCustomerButton: {
        background: "#2d5b75",
        color: "#fff",
        width: "auto",
        height: "35px"
    },
    PopUpAddButton: {
        display: "flex",
        "justifyContent": "center",
        width: "auto",
        margin: "auto"
    },
    printLoader: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        background: "rgb(251 248 248 / 60%)",
        zIndex: 999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
}


