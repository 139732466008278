import React from "react";
import { Document, Page, PDFViewer, StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import peak_packers from "../../images/peak_packers_logo.jpg"
import company_name_logo from "../../images/peak_wood.png"

const styles = StyleSheet.create({
    header: {
        padding: "0px 10px"
    },
    header_top: {
        display: "flex",
        "flexDirection": "row",
        "borderBottom": "1px solid #ccc",
    },
    header_bottom: {
        display: "flex",
        "flexDirection": "column",
    
    },
    gst_number: {

    },
    header_center: {
        display: "flex",
        "justifyContent": "center",
        flex: 3,
        marginTop: "3px",
        "alignItems": "center",
    },
    tax_invoice: {
        fontSize: "11px"
    },
    company_logo: {
        width: "30%"
    },
    logo_text: {
        width: "90%",
        // marginTop: "-10px"
    },
    mobile_number: {
        width: "100%",
        textAlign: "right"
    },
    mobile_number_1: {
        width: "100%",
        textAlign: "right"
    },
    email: {
        width: "100%",
        textAlign: "right"
    },
    header_bottom: {
        marginTop: "5px"
    },  
    address: {
        "textAlign": "center"
    },
    address_1: {
        "textAlign": "center"
    }
});

const Header = () => (
    <View style={styles.header}>
        <View style={styles.header_top}>
        <View style={{flex: 1.5}}>
            <Text style={styles.gst_number}>GSTIN:33CHMPM1634H1ZE</Text>
            <Image src={company_name_logo} style={styles.company_logo}></Image>
        </View>
        <View style={styles.header_center}>
            <Text style={styles.tax_invoice}>TAX INVOICE</Text>
            <Image src={peak_packers} style={styles.logo_text}></Image>
        </View>
        <View style={{flex: 1.5, display: "flex", justifyContent: "flex-start", flexDirection: "column", alignItems: "flex-end"}}>
            <Text style={styles.mobile_number}>MOBILE: 9384837676</Text>
            <Text style={styles.mobile_number_1}>7092662224</Text>
            <Text style={styles.email}>E-mail: peakpackers@gmail.com</Text>
        </View>
        </View>
        <View style={styles.header_bottom}>
            <Text style={styles.address}>Factory : PWD Bangalow Road, 114 Nallur Village Solavaram Post, Chennai, Tamil Nadu, Pin: 600067</Text>
            <Text style={styles.address_1}>Mfg:- Pallets, Wooden Boxes, Wood Crates, Ply Wood Boxes and Export Boxes for All Packing Needs </Text>
        </View>
    </View>
);

export default Header;
