import React, { Component, useEffect, useState } from "react";
import CustomerComponent from "./CustomerComponent";
import ProductComponent from "./ProductComponent";
import Header from "./Header";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import InvoiceComponent from "./InvoiceComponent";
import SalesReportComponent from "./SalesReportComponent";
import AddOrUpdateCustomer from "./AddOrUpdateCustomer";
import LoginComponent from "./LoginComponent";
import service from "../common/service";
import style from "../css/common.js";
import loader from "../images/loader.gif";
import AddOrUpdateProduct from "./AddOrUpdateProduct";
// import { useNavigate } from "react-router-dom";

const ViewComponent = (props) =>  {

    const [state, setState] = useState({
        isPageExist: false,
        isLoading: true
    });

    useEffect(() => {
        if(props.selectedMenu == "invoice") {
            doLogin();
        }
        
    }, [props.selectedMenu]);

    useEffect(() => {
            doLogin();
        
    }, []);

    const doLogin = ()  => {
        let userkey = localStorage.getItem("user");
        let { pathname } = window.location;
        let currentMenus = pathname.split("/");
        currentMenus.splice(0, 1);
        let pages = ["", "/", "invoice", "customers", "products", "sales", "login", "add_customer", "update_customer", "add_product", "update_product"];
        if (userkey  || (window.location.pathname == "/invoice" && userkey)) {
            service.verifyUserDetails(userkey).then(user => {
                if (user.username) {
                    currentMenus[0] = "invoice"
                    props.setUserDetails(user);
                    if (pages.includes(currentMenus[0])) {
                            props.setCurrentMenu(currentMenus.length ? currentMenus[0] : "invoice");
                        setState(() => ({
                            ...state,
                            isPageExist: true,
                            isLoading: false
                        }))
                    }
                } else {
                    props.setCurrentMenu("login");
                }

            }).catch(err => {
                props.setCurrentMenu("login");
            })
        } else {
            props.setCurrentMenu("login");
        }
    }

    const renderContent = (props) => {
        let container;
        switch (props.selectedMenu) {
            case "/":
                container = <InvoiceComponent />
                break;
            case 'invoice':
                container = <InvoiceComponent />
                break;
            case 'customers':
                container = <CustomerComponent />
                break;
            case 'add_customer':
                container = <AddOrUpdateCustomer isUpdate={false} />
                break;
            case 'update_customer':
                container = <AddOrUpdateCustomer isUpdate={true} />
                break;
            case 'add_product':
                container = <AddOrUpdateProduct isUpdate={false} />
                break;
            case 'update_product':
                container = <AddOrUpdateProduct isUpdate={true} />
                break;
            case 'products':
                container = <ProductComponent />
                break;
            case 'sales':
                container = <SalesReportComponent />
                break;
            case 'login':
                container = <LoginComponent />
                break;
            default:
                container = <div>404 - Not Found</div>
        }
        return container
    }

        return (
            <div className="view-component-container" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                { ( props.selectedMenu != "login" ? <div style={{ width: "90%", display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "45px" }}>
                    <Header />
                    {renderContent(props)}
                </div> : props.selectedMenu == "login" ? <LoginComponent /> : ( !state.isLoading ? <div>404 - Not Found</div> : (<div className="loading" style={{ ...style.printLoader }}>
                    <img src={loader} style={{ width: "45px", height: "45px" }} />
                </div>)))}
            </div>
        )

}

const mapStateToProps = state => {
    return {
        selectedMenu: state.selectedMenu
    }
};

const mapDispatchToProps = dispatch => ({
    setCurrentMenu: data => dispatch(ACTIONS.setCurrentMenu(data)),
    setUserDetails: data => dispatch(ACTIONS.setUserDetails(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ViewComponent);