import React from "react";
import { Document, Page, PDFViewer, StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import stamp_with_sign from "../../images/stamp_with_sign.png";

const styles = StyleSheet.create({
    footer: {
        padding: "0px 10px"
    },
    footer_top: {
        display: "flex",
        "flexDirection": "row",
    },
    footer_left: {
        display: "flex",
        "justifyContent": "center",
        flex: 1,
        marginTop: "3px",
        "alignItems": "center",
    },
    stamp_with_sign: {
        width: "50%",
        position: "relative",
        top: "10px",
        marginTop: "-20px",
        marginLeft: "50px"
    }
});

const FooterComponent = () => (
    <View style={styles.footer}>
        <View style={styles.footer_top}>
            <View style={{ flex: 1 }}>
                <Text style={{padding: "3px 0px"}}>Bank Details</Text>
                <Text style={{padding: "3px 0px 0px 0px"}}>Bank Name: Canara Bank</Text>
                <Text style={{padding: "3px 0px  0px 0px"}}>Account Number: 9921201002399</Text>
                <Text style={{padding: "3px 0px  0px 0px"}}>IFSC Code: CNRB0001492</Text>
                <Text style={{padding: "3px 0px  0px 0px"}}>Branch: Karanodai</Text>
            </View>
            <View style={styles.footer_left}>
                <Text style={{textAlign: "center"}}>For Peak Wood Packers</Text>
                <Image src={stamp_with_sign} style={styles.stamp_with_sign}></Image>
                <Text style={{textAlign: "center", marginLeft: "20px"}}>Authorised Signatory</Text>
            </View>
        </View>
    </View>
);

export default FooterComponent;
