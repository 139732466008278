import state_list from "../data/state_list.json";
import style from "../css/common.js"
import "../css/common.css"
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import service from "../common/service";
import {
    Form,
    Input,
    Button,
    Select,
    InputNumber,
    Typography,
    Row,
    Col,
} from 'antd';
import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";

const spanValues = isMobile ? 24 : 12;

const { Text } = Typography;

const AddOrUpdateProduct = (props) => {

    const [productForm] = Form.useForm()
    const [updateValues, setUpdateValues] = useState(props.isUpdate ? props.productData : {});
    const [customerList, setCustomerList] = useState([]);
    const [productDimension, setProductDimension] = useState({ length: props.isUpdate && props.productData ? props.productData.product_length : 0, width: props.isUpdate && props.productData ? props.productData.product_width : 0, height: props.isUpdate && props.productData ? props.productData.product_height : 0 });
    const [productsList, setProductsList] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        setUpdateValues(props.productData)
    }, [props.productData]);

    useEffect(() => {
        if (props.selectedMenu == "update_product") {
            service.GET("get_product_data", [], {}).then(res => {
                let productList = JSON.parse(JSON.stringify(res && res.length ? res : []));
                let product_category = (productList || []).reduce((acc, obj) => {
                    if (!acc.includes(obj.product_category)) {
                        acc.push(obj.product_category)
                    }
                    return acc;
                }, []).map(m => <Select.Option key={m} value={m}>{`${m}`}</Select.Option>);
                setProductsList(productList);
            }).catch(err => {
                console.log(err)
            });
        }
    }, [props.selectedMenu])

    useEffect(() => {
        service.DISTINCT("customer_index", { column: "customer_name", value: "_id", orderby: "asc" }).then((data, err) => {
            if (data) {
                const customer = data.map(m => <Select.Option key={m._id} value={m._id}>{`${m.customer_name}`}</Select.Option>)
                setCustomerList(customer)
            }
        })
    }, []);



    const handleDimensionChange = (column, value) => {
        let obj = Object.assign({}, productDimension);
        obj[column] = value;
        setProductDimension(obj);
    }
    const renderCustomerSelection = () => {
        
        const productsCategory = (productsList || []).reduce((acc, obj) => {
            if (!acc.includes(obj.product_category)) {
                acc.push(obj.product_category)
            }
            return acc;
        }, []).map(m => <Select.Option key={m} value={m}>{`${m}`}</Select.Option>);
        return (
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="product_category"
                        labelAlign="left" label="Product Category"
                        rules={[{ required: true, message: 'Please input Product Category!' }]}>
                        <Select showSearch placeholder="Select Product Category"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {
                                let filteredProducts = productsList.filter(m => {
                                    if (value == m.product_category) {
                                        return true
                                    }
                                });
                                setFilteredProducts(filteredProducts.map(m => <Select.Option key={m._id} value={m._id}>{`${m.product_length} X ${m.product_width} X ${m.product_height}`}</Select.Option>));
                                setUpdateValues({})
                            }}
                        >
                            {productsCategory}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name='product_id'
                        labelAlign="left" label="Product Dimension"
                        rules={[{ required: true, message: 'Please input Product Name!' }]}>
                        <Select showSearch placeholder="Select Product Dimension"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {

                                productsList.forEach(m => {
                                    if (m._id == value) {
                                        setUpdateValues(m);
                                        setProductDimension({
                                            length: m.product_length,
                                            height: m.product_height,
                                            width: m.product_width,
                                        })
                                    }
                                })

                            }}
                        >
                            {filteredProducts}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const renderPrdouctForm = () => {
        return (
            <Form
                form={productForm}
                name="product"
                layout="horizontal"
                scrollToFirstError
                onFinish={(data) => handleProductChange(data, props)}
                initialValues={{ ...updateValues }}
                autoComplete="off"
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="customer_id" labelAlign="left" label="Customer Name"
                            rules={[{ required: true, message: 'Please input customer!' }]}>
                            <Select showSearch placeholder="Select Customer Name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {customerList}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                {<Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_category" labelAlign="left" label="Product Category"
                            rules={[{ required: true, message: 'Please input Product Category!' }]}>
                            <Input style={{ ...style.ConvertToUpperCase }} />
                        </Form.Item>
                    </Col>
                </Row>}
                <Row>
                    <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 12 }}
                            wrapperCol={{ span: isMobile ? 24 : 9 }}
                            name="product_length" labelAlign="left" label="Length"
                            rules={[{ required: true, message: 'Please input length!' }]}>
                            <Input type="number" onChange={e => handleDimensionChange("length", e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 9 }}
                            name="product_width" labelAlign="left" label="Width"
                            rules={[{ required: true, message: 'Please input width!' }]}>
                            <Input type="number" onChange={e => handleDimensionChange("width", e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 9 }}
                            name="product_height" labelAlign="left" label="Height"
                            rules={[{ required: true, message: 'Please input height!' }]}>
                            <Input type="number" onChange={e => handleDimensionChange("height", e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }} labelAlign="left" label="Product Dimension">
                            <Text strong>{`${productDimension.length} X ${productDimension.width} X ${productDimension.height}`}</Text>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    {
                        props.isUpdate ? <Col span={24}>
                            <Form.Item
                                labelCol={{ span: isMobile ? 24 : 4 }}
                                wrapperCol={{ span: isMobile ? 24 : 17 }}
                                name="_id" labelAlign="left" label="Product ID"
                                rules={[{ required: true, message: 'Please input Product ID!' }]}>
                                <Input style={{ ...style.ConvertToUpperCase }} disabled={true} />
                            </Form.Item>
                        </Col> : null
                    }
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_description" labelAlign="left" label="Product Description"
                            rules={[{ type: 'string', required: true, message: 'Please input valid product description!', min: 4, max: 300 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_hsn_code" labelAlign="left" label="Product HSN Code"
                            rules={[{ required: true, message: 'Please input product HSN code!', type: "string", min: 4, max: 8 }]}>
                            <Input style={{ ...style.ConvertToUpperCase }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_price" labelAlign="left" label="Product Price"
                            rules={[{ required: true, message: 'Please input valid product price!' }]}>
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item style={style.PopUpAddButton}>
                    <Button style={{ ...style.AddCustomerButton, width: "100%" }} htmlType="submit" >{props.isUpdate ? "UPDATE" : "ADD PRODUCT"}</Button>
                </Form.Item>

            </Form>
        )
    }
    return (
        <div className="add-customer-container">
            <div>
                {!isDesktop && isMobile ? <div>
                    {isMobile && props.isUpdate && <div style={{ fontSize: "18px", fontWeight: 600, padding: "5px 0px 15px 0px" }}>Update Customer Details</div>}
                    {isMobile && !props.isUpdate && <div style={{ fontSize: "18px", fontWeight: 600, padding: "5px 0px 15px 0px" }}>Add Customer Details</div>}
                    { props.isUpdate && renderCustomerSelection()}
                    {(updateValues && Object.keys(updateValues).length) || !props.isUpdate ? renderPrdouctForm() : null}
                </div> : null}
                {isDesktop && !isMobile ? renderPrdouctForm() : null}
            </div>
        </div>
    )
}


const handleProductChange = (data, props) => {
    console.log(data, props)
    let productData = data || {};
    productData.product_category = productData.product_category.toUpperCase();
    Object.keys(productData).forEach(key => {
        if (!productData[key]) {
            productData[key] = ""
        }
    });
    if (props.isUpdate && typeof productData == "object") {
        service.UPDATE("update_product_data", [productData]).then(res => {
            props.setProductTableData();
        }).catch(err => err);
    } else {
        service.UPDATE("add_product_data", [productData]).then(res => {
            props.setProductTableData();
        }).catch(err => err)
    }
    props.closePopup()

}

const mapStateToProps = state => {
    return {
        selectedMenu: state.selectedMenu
    }
};

const mapDispatchToProps = dispatch => ({
    setProductTableData: data => dispatch(service.fetchProductData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOrUpdateProduct);