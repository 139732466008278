import React from "react";
import { Document, Page, PDFViewer, StyleSheet, View, Text, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    customer_container: {
        borderBottom: "1px solid #000",
        display: "flex",
        flexDirection: "row",
        // marginTop: "10px"
    },
    left_portion: {
        flex: 1,
        borderRight: "1px solid #000",
    },
    right_portion: {
        flex: 1,
    }
});

const ReceiverDetails = (props) => {
    let {receiver_details} = props.pdfInfo || {};
    return (
        <View style={styles.customer_container}>
            <View style={styles.left_portion}>
                <Text style={{padding: "3px 5px", textAlign: "center", borderBottom: "1px solid #000",}}>Product Received By</Text>
                <View style={{}}>
                    <View style={{display: "flex", flexDirection: "row", borderBottom: "1px solid #000"}}>
                        <Text style={{flex: 1, padding: "3px 5px", borderRight: "1px solid #000",}}>Name : </Text>
                        <Text style={{flex: 3, padding: "3px 5px"}}>{receiver_details.receiver_name}</Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row"}}>
                        <Text style={{flex: 1, padding: "3px 5px", borderRight: "1px solid #000",}}>Contact : </Text>
                        <Text style={{flex:3, padding: "3px 5px"}}>{receiver_details.receiver_phone}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.right_portion}>
                <Text style={{padding: "3px 5px", textAlign: "center", borderBottom: "1px solid #000",}}>Vechile Details</Text>
                <View style={{}}>
                    <View style={{display: "flex", flexDirection: "row", borderBottom: "1px solid #000"}}>
                        <Text style={{flex: 2, padding: "3px 5px", borderRight: "1px solid #000",}}>Registration Number : </Text>
                        <Text style={{flex: 3, padding: "3px 5px"}}>{receiver_details.receiver_rc_number}</Text>
                    </View>
                    <View style={{display: "flex", flexDirection: "row"}}>
                        <Text style={{flex: 2, padding: "3px 5px", borderRight: "1px solid #000",}}>Driving Licence : </Text>
                        <Text style={{flex:3, padding: "3px 5px"}}>{receiver_details.receiver_dl}</Text>
                    </View>
                </View>
            </View>
        </View>
    )
};

export default ReceiverDetails;