import axios from "axios";
import ACTIONS from "../redux/actions";

const hostName = "https://us-central1-peakpackersnode.cloudfunctions.net/App/"
// const hostName = "http://localhost:8000/"
class Service {

    LOGIN(username, password) {
        if (username && password) {
            return axios.post(`${hostName}${"login"}`, {
                username,
                password
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                return res.data;
            }).catch(err => {
                console.log(err)
            })
        }
    }

    GET(request, filter, data) {
        if (request && typeof request == "string") {
            return axios.post(`${hostName}${"search"}`, {
                queryName: request,
                filters: Array.isArray(filter) ? filter : (typeof filter == "object" ? [filter] : [])
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                return Array.isArray(res.data) && res.data.length ? res.data : [];
            }).catch(err => {
                console.log(err)
            })
        }
    }

    UPDATE(request, data) {
        if (request && typeof request == "string") {
            return axios.post(`${hostName}${"update"}`, {
                queryName: request,
                data: !Array.isArray(data) && typeof data == "object" ? data : (Array.isArray(data) && data.length ? data[0] : {})
            }).then(res => {
                if (res.data.exist) {
                    alert("Already Exist")
                }
                return res.data
            }).catch(err => {
                console.log(err, "test")
            })
        }
    }

    DELETE(request, filters) {
        if (request && typeof request == "string") {
            return axios.post(`${hostName}${"delete"}`, {
                queryName: request,
                filters: Array.isArray(filters) ? filters : (typeof filters == "object" ? filters : [])
            }).then(data => {
                return data
            }).catch(err => {
                console.log(err)
            })
        }
    }

    DISTINCT(indexName, groupby, orderby) {
        if (indexName && typeof indexName == "string") {
            return axios.post(`${hostName}${"distinct"}`, {
                indexName: indexName,
                groupby: Array.isArray(groupby) ? groupby[0] : (typeof groupby == "object" ? groupby : {}),
                orderby
            }).then(res => {
                return res.data
            }).catch(err => {
                console.log(err)
            })
        }
    }

    fetchCustomerData(data) {
        return ((dispatch, getState) => {
            return this.GET("get_customer_data", [], {}).then(res => {
                dispatch(ACTIONS.setCustomerTableData(res && res.length ? res : res));
            }).catch(err => {
                console.log(err)
            });
        })
    }

    fetchProductData(data) {
        return ((dispatch, getState) => {
            return this.GET("get_product_data", [], {}).then(res => {
                dispatch(ACTIONS.setProductTableData(res && res.length ? res : res));
            }).catch(err => {
                console.log(err)
            });
        })
    }

    verifyUserDetails(token) {
        if (token) {
            return axios.post(`${hostName}${"user"}`, {
                userkey: token
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                return res.data;
            }).catch(err => {
                return err;
            })
        }
    }

    sortByKey(data, key, order) {
        let orderValue = order == "desc" ? -1 : 1;
        let sortedData = data.sort((a, b) => {
            if (a[key] > b[key])
                return orderValue;
            if (a[key] < b[key])
                return orderValue * -1;
            return 0;
        });
        return sortedData;
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
        }
        return splitStr.join(' '); 
     }

     navigate(pathname) {
        var refresh = window.location.protocol + "//" + window.location.host + pathname;    
        window.history.pushState({ path: refresh }, '', refresh);
     }
}

const service = new Service;
export default service;