import React, { useEffect, useState } from "react";
import AntdTableComponent from './AntdTableComponent';
import { Button, Input, Tooltip, Popconfirm, message, Typography, Switch } from "antd";
import { connect } from "react-redux";
import service from "../common/service";
import { CloudDownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDownLoader from "./PdfComponent/PdfDownLoader";
import style from "../css/common.js";
import loader from "../images/loader.gif";
import LoaderComponent from "./LoaderComponent";
const currencyFormatter = require('currency-formatter');

const { Text } = Typography;
let pdf_flag = true;
const SalesReportComponent = (props) => {

    const [isDataChange, renderNull] = useState(false);
    const [salesReportData, setSalesReportData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [openPdf, setOpenpdf] = useState(false);
    const [pdfInfo, setPdfInfo] = useState({});
    const [isLoading, setLoading] = useState(false);
    const salesTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: true,
        scrollX: 900,
        scrollY: 600,
        pageSize: 10,
        showIndex: false,
        indexTitle: "S.NO",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: 'S.NO',
                key: 'sno',
                width: '30px',
                render: (text, object, index) => index + 1

            },
            {
                title: "INVOICE NUMBER",
                dataIndex: "invoice_number",
                nullText: "NA",
                sort: true,
                width: 70,
                showFilter: false,
                fixed: 'left',
            },
            {
                title: "CUSTOMER NAME",
                dataIndex: "customer_name",
                nullText: "NA",
                sort: true,
                width: 200,
                showFilter: false,
            },
            {
                title: "INVOICE DATE",
                dataIndex: "invoice_date",
                nullText: "NA",
                sort: true,
                width: 80,
                showFilter: false,
            },
            {
                title: "AMOUNT",
                dataIndex: "total_amount",
                nullText: "NA",
                sort: false,
                width: 80,
                showFilter: false,
            },
            {
                title: "AMOUNT IN WORDS",
                dataIndex: "amount_in_words",
                nullText: "NA",
                sort: false,
                width: 240,
                showFilter: false,
            },
            {
                title: "STATUS",
                dataIndex: "status",
                sort: false,
                width: 50,
                showFilter: false,
                align: "center",
                render: (text, record, index) => {
                    let color;
                    console.log(text, record)
                    if(text == "Draft") {
                        color = "#000"
                    } else if (text == "Approved") {
                        color = "#00af80"
                    } else if (text == "Rejected") {
                        color = "#f37070"
                    }
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Popconfirm
                                placement="topRight"
                                title="Update Invoice Status"
                                cancelText="Reject"
                                okText = "Approve"
                                disabled={text == "Approved" || text == "Rejected"}
                                onConfirm={e => handleStatus("Approved", record)}
                                onCancel={e => handleStatus("Rejected", record)}
                            >
                                <span>
                                    <Text key={index} style={{color: color, cursor: "pointer" }}>
                                        {text}
                                    </Text>
                                </span>
                            </Popconfirm>
                        </div>
                    )
                }
            },
            {
                title: "PRINT",
                sort: false,
                width: 60,
                showFilter: false,
                fixed: 'right',
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Click to download Invoice"}>
                                <Button onClick={e => handlePdfDownload(record)} icon={<CloudDownloadOutlined />} style={{ ...style.TableIcons, backgroundColor: "#00af80" }} />
                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }

    useEffect(() => {
        getSalesData()
    }, []);

    useEffect(() => {
        renderNull(!isDataChange)
    }, [salesReportData]);

    const handlePdfDownload = (record) => {
        pdf_flag = true;
        let data = salesData;
        let obj;
        data.forEach(m => {
            if(m.invoice_number == record.invoice_number) {
                obj = Object.assign({}, m);
            }
        });

        setPdfInfo(obj);
        setOpenpdf(true);
        setLoading(true);
    }

    const getSalesData = () => {
        service.GET("get_sales_data", [], {}).then(res => {
            let data = Array.isArray(res) && res.length ? res : [];
            let finalData = data.map(m => {
                let obj = Object.assign({}, m);
                var date = new Date(m.invoice_date);
                let dd = String(date.getDate()).padStart(2, '0');
                let mm = String(date.getMonth() + 1).padStart(2, '0');
                let yyyy = date.getFullYear();
                const current_date = `${dd}/${mm}/${yyyy}`;
                obj["customer_name"] = m.customer_details.customer_name;
                obj["invoice_date"] = current_date;
                obj["total_amount"] = `₹    ${currencyFormatter.format(m.summary_details.total_amount, { code: 'INR' }).substr(1)}`;
                obj["amount_in_words"] = m.summary_details.amount_in_words;
                return obj;
            });
            finalData = service.sortByKey(finalData, "invoice_date", "desc")
            setSalesReportData(finalData);
            setSalesData(finalData)
            renderNull(false)
        }).catch(err => err);
    }
    const handleStatus = (value, record) => {
        let data = salesData.filter(m => m.invoice_number == record.invoice_number);
        if(data && data.length && value) {
            setSalesReportData([])
            service.UPDATE("update_sales_data", {_id: data[0]["invoice_number"], status: value}).then(res => {
                getSalesData();
                message.success('Invoice Updated')
            }).catch(err => {
                console.log(err)
            })
            
        }
    }

    const handleSearch = (e) => {
        
        let value = e.target.value;
        let data = [...salesData];

        let finalData = data.filter(m => m.invoice_number.toString().toLowerCase().indexOf(value.toLowerCase()) > -1 || m.customer_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
        finalData = service.sortByKey(finalData, "invoice_date", "desc")
        if (value.length) {

            setSalesReportData(finalData.length ? finalData : salesData);
        } else {
            setSalesReportData(finalData);
        }

        renderNull(true);
    }
    const filterbyStatus = (checked) => {
        let data = [...salesData];
        let finalData = [...salesData];
        if(checked) {
            finalData = data.filter(m => m.status == "Approved");
        }
        finalData = service.sortByKey(finalData, "invoice_date", "desc");
        setSalesReportData(finalData);

        renderNull(true);
    }

    

    return (
        <div className="sales-container" style={{ width: "100%" }}>
            {
                <div>
                    <Switch onChange={filterbyStatus} checkedChildren="Approved" unCheckedChildren="All"/>
                </div>
            }
            <div>
                {
                    <div style={style.CustomerSearch}>
                        <Input placeholder="Search here..."
                            allowClear
                            addonAfter={<SearchOutlined />}
                            size="large"
                            onChange={handleSearch} />
                    </div>

                }
            </div>
            {

                salesReportData ? (!isDataChange ? <AntdTableComponent data={salesReportData} config={{ ...salesTableConfig }} /> : null) : <LoaderComponent />
            }
            {
                openPdf ? <PDFDownloadLink document={<PdfDownLoader pdfInfo={pdfInfo} />} fileName={`${pdfInfo.invoice_number}_${pdfInfo.invoice_date}`}>
                {({ blob, url, loading, error }) => {
                    if(blob && pdf_flag) {
                        const fileURL = URL.createObjectURL(blob);
                        const pdfWindow = window.open();
                        pdfWindow.location.href = fileURL;
                        setOpenpdf(false);
                        setPdfInfo({});
                        setLoading(false);
                        pdf_flag = false
                    }
                }
                 
                }
              </PDFDownloadLink> : null
            }
            {isLoading ? <div className="loading" style={{ ...style.printLoader }}>
                <img src={loader} style={{ width: "45px", height: "45px" }} />
            </div> : null}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        // customerTableData: state.customerTableData
    }
};

const mapDispatchToProps = dispatch => ({
    // setCustomerTableData: data => dispatch(service.fetchCustomerData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesReportComponent);