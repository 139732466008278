import { Form, Select, Button, Input, Row, Col, Checkbox, Divider, DatePicker } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import style from "../css/common.js";
import state_list from "../data/state_list.json";
import service from "../common/service";
import { isMobile } from "react-device-detect";

const spanValues = isMobile ? 24 : 12;
export const renderInvoiceNumber = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let current_date = `${dd}/${mm}/${yyyy}`;
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 :6 }}
                        wrapperCol={{ span: isMobile ? 24 :10 }}
                        name="invoice_number" labelAlign="left" label="Invoice No"
                        rules={[{ required: true, message: '' }]}>
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                        name="invoice_date" labelAlign="left" label="Date"
                        rules={[{ required: true, message: '' }]}>
                        <DatePicker
                            format='DD/MM/YYYY'
                            dateRender={current => {
                                const style = {};
                                if (current.date() === 1) {
                                    style.border = '1px solid #1890ff';
                                    style.borderRadius = '50%';
                                }
                                return (
                                    <div className="ant-picker-cell-inner" style={style}>
                                        {current.date()}
                                    </div>
                                );
                            }}
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export const renderCustomerDetails = (form, state, setState) => {
    const customers = state.customerList.map(m => <Select.Option key={m._id} value={m._id}>{`${m.customer_name}`}</Select.Option>)
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                        name="customer_id" labelAlign="left" label="Customer Name"
                        rules={[{ required: true, message: 'Please input Customer Name!' }]}>
                        <Select showSearch placeholder="Select Customer Name"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {
                                let obj = {};
                                let filters = [{ column: "customer_id", value: [value] }]
                                service.GET("get_product_data", filters, {}).then(res => {
                                    let productList = JSON.parse(JSON.stringify(res && res.length ? res : []))
                                    setState(() => ({
                                        ...state,
                                        productList: productList
                                    }))
                                }).catch(err => {
                                    console.log(err)
                                });
                                state.customerList.forEach(m => {
                                    if (value == m._id) {
                                        let state_name = state_list.reduce((acc, obj) => {
                                            if (obj.state_code == m.state_code) {
                                                acc = obj.state_name;
                                            }
                                            return acc;
                                        }, "")
                                        form.setFieldsValue({
                                            customer_email: m.customer_email,
                                            customer_phone: m.customer_phone,
                                            customer_gst_number: m.customer_gst_number,
                                            customer_msme_number: m.customer_msme_number,
                                            state_code: `${m.state_code} - ${state_name}`,
                                            customer_address: `${m.customer_address_line1}, ${m.customer_address_line2 ? m.customer_address_line2 + "," : ""} ${m.customer_address_line3 ? m.customer_address_line3 + "," : ""} ${m.city}, ${state_name} - ${m.zip_code}`,
                                        })
                                    }
                                });
                            }}
                        >
                            {customers}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="customer_order_number" labelAlign="left" label="P.Order Number"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="customer_email" labelAlign="left" label="E-mail"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                    >
                        <Input disabled style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="customer_phone" labelAlign="left" label="Phone"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}>
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="customer_gst_number" labelAlign="left" label="Customer GST"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }} >
                        <Input style={{ ...style.ConvertToUpperCase }} disabled />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="customer_msme_number" labelAlign="left" label="Customer MSME No"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}>
                        <Input style={{ ...style.ConvertToUpperCase }} disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="customer_address" labelAlign="left" label="Address"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }} >
                        <Input.TextArea autoSize disabled style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="state_code" labelAlign="left" label="State"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}>
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}


export const renderProductDetails = (form, state, setState) => {
    const productsCategory = (state.productList || []).reduce((acc, obj) => {
        if(!acc.includes(obj.product_category)) {
            acc.push(obj.product_category)
        }
        return acc;
    }, []).map(m => <Select.Option key={m} value={m}>{`${m}`}</Select.Option>);
    return (
        <Form.List name="products" rules={[{ required: true, message: 'Please add atleast one product!' }]}>
            {(fields, { add, remove }) => {
                return (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }) => {
                            return (
                                <div key={key}>
                                    <MinusCircleOutlined onClick={() => remove(name)} style={{ width: "20px", position: "relative", left: "95%" }} />
                                    <Row>
                                        <Col span={spanValues}>
                                            <Form.Item
                                                labelCol={{ span: isMobile ? 24 : 6 }}
                                                wrapperCol={{ span: isMobile ? 24 : 12 }}
                                                {...restField}
                                                name={[name, 'product_category']}
                                                fieldKey={[fieldKey, 'product_category']}
                                                labelAlign="left" label="Product Category"
                                                rules={[{ required: true, message: 'Please input Product Category!' }]}>
                                                <Select showSearch placeholder="Select Product Category"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onSelect={value => {
                                                        let filteredProducts = state.productList.filter(m => {
                                                            if (value == m.product_category) {
                                                                return true
                                                            }
                                                        });
                                                        let selected = form.getFieldValue("products");
                                                        let obj = {
                                                            product_id: null,
                                                            product_description: null,
                                                            product_category: value,
                                                            product_hsn_code: null,
                                                            product_price: null,
                                                            quantity: null,
                                                        }
                                                        selected[name] = obj;
                                                        form.setFieldsValue({
                                                            products: [...selected]
                                                        })
                                                        setState(() => ({
                                                            ...state,
                                                            selectedProduct: filteredProducts.map(m => <Select.Option key={m._id} value={m._id}>{`${m.product_length} X ${m.product_width} X ${m.product_height}`}</Select.Option>)
                                                        }));

                                                    }}
                                                >
                                                    {productsCategory}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={spanValues}>
                                            <Form.Item
                                                labelCol={{ span: isMobile ? 24 : 6 }}
                                                wrapperCol={{ span: isMobile ? 24 : 12 }}
                                                {...restField}
                                                name={[name, 'product_id']}
                                                fieldKey={[fieldKey, 'product_id']}
                                                labelAlign="left" label="Product Dimension"
                                                rules={[{ required: true, message: 'Please input Product Name!' }]}>
                                                <Select showSearch placeholder="Select Product Name"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onSelect={value => {

                                                        let obj = {}, isExist = 0;
                                                        let selected = form.getFieldValue("products");
                                                        let selectedIds = selected.filter(item => item.product_id == value);
                                                        if (selectedIds.length > 1) {
                                                            alert("Products Already Selected!!")
                                                            obj["product_id"] = null;
                                                            obj["product_description"] = null;
                                                            obj["product_category"] = null;
                                                            obj["product_hsn_code"] = null;
                                                            obj["product_price"] = null;
                                                            obj["quantity"] = null;
                                                            selected[name] = obj;
                                                            return 0
                                                        } else {
                                                            state.productList.forEach(m => {
                                                                if (value == m._id) {
                                                                    obj["product_description"] = m.product_description;
                                                                    obj["product_category"] = m.product_category;
                                                                    obj["product_hsn_code"] = m.product_hsn_code;
                                                                    obj["product_price"] = m.product_price;
                                                                    obj["quantity"] = 1;
                                                                    obj["product_id"] = m._id;
                                                                }
                                                            });
                                                        }
                                                        let data = selected.map(m => {
                                                            if (m.product_id == value) {
                                                                m = Object.assign({}, obj)
                                                            }
                                                            return m
                                                        })
                                                        form.setFieldsValue({
                                                            products: [...data]
                                                        })
                                                    }}
                                                >
                                                    {state.selectedProduct}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={spanValues}>
                                            <Form.Item
                                                labelCol={{ span: isMobile ? 24 : 6 }}
                                                wrapperCol={{ span: isMobile ? 24 : 12 }}
                                                {...restField}
                                                name={[name, 'product_description']}
                                                fieldKey={[fieldKey, 'product_description']}
                                                labelAlign="left" label="Product Description"
                                            >
                                                <Input disabled style={{ ...style.ConvertToUpperCase }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={spanValues}>
                                            <Form.Item
                                                labelCol={{ span: isMobile ? 24 : 6 }}
                                                wrapperCol={{ span: isMobile ? 24 : 12 }}
                                                {...restField}
                                                name={[name, 'product_hsn_code']}
                                                fieldKey={[fieldKey, 'product_hsn_code']}
                                                labelAlign="left" label="Product HSN CODE"
                                            >
                                                <Input disabled style={{ ...style.ConvertToUpperCase }}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={spanValues}>
                                            <Form.Item
                                                labelCol={{ span: isMobile ? 24 : 6 }}
                                                wrapperCol={{ span: isMobile ? 24 : 12 }}
                                                {...restField}
                                                name={[name, 'product_price']}
                                                fieldKey={[fieldKey, 'product_price']}
                                                labelAlign="left" label="Price"
                                                rules={[{ required: true, message: 'Please input price!' }]}
                                            >
                                                <Input prefix="&#x20b9;"  type="number"/>
                                            </Form.Item>
                                        </Col>
                                        <Col span={spanValues}>
                                            <Form.Item
                                                labelCol={{ span: isMobile ? 24 : 6 }}
                                                wrapperCol={{ span: isMobile ? 24 : 12 }}
                                                {...restField}
                                                name={[name, 'quantity']}
                                                fieldKey={[fieldKey, 'quantity']}
                                                labelAlign="left" label="Quantity"
                                                rules={[{ required: true, message: 'Please input quantity!' }]}
                                            >
                                                <Input type="number" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Divider />
                                </div>
                            )
                        })}

                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <Col span={isMobile ? 24 : 4}>
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add Product
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </>
                )
            }}
        </Form.List>
    )
}

export const renderGSTDetails = (form, state, setState) => {
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 12 }}
                        name="cgst"
                        labelAlign="left" label="Central GST"
                        rules={[{ required: true, message: 'Please input CGST!' }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 12 }}
                        name="sgst"
                        labelAlign="left" label="State GST"
                        rules={[{ required: true, message: 'Please input SGST!' }]}
                    >
                        <Input type="number"/>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export const renderOtherDetails = (form, state, setState) => {
    const woodTypes = [
        { label: 'Pine Wood', value: 'pineWood' },
        { label: 'Ply Wood', value: 'plyWood' },
        { label: 'Rubber Wood', value: 'rubberWood' },
    ]
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 3 }}
                        wrapperCol={{ span: isMobile ? 24 : 24 }}
                        name="material_details" labelAlign="left" label="Material Type"
                        rules={[{ required: true, message: 'Please input material type!' }]}>
                        <Checkbox.Group options={woodTypes} style={{width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", padding: isMobile ? "0px 20px" : "unset"}}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                        name="place_of_supply" labelAlign="left" label="Place of Supply"
                        rules={[{ required: true, message: 'Please input place of supply!' }]}>
                        <Input style={{ ...style.ConvertToCapitalCase }}/>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export const renderReceiverDetails = (form, state, setState) => {
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="receiver_name" labelAlign="left" label="Receiver Name"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="receiver_phone" labelAlign="left" label="Phone"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="receiver_dl" labelAlign="left" label="Driving Licence"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="receiver_rc_number" labelAlign="left" label="Registration Number"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}