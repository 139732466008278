import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
    Typography,
    Row,
    Divider,
    Col,
} from "antd";
import { connect } from "react-redux";
import service from "../common/service";
import style from "../css/common.js"
import "../css/common.css"
import loader from "../images/loader.gif"
import LoaderComponent from "./LoaderComponent";
import ModalComponent from "./ModalComponent";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfDownLoader from "./PdfComponent/PdfDownLoader";
import { PDFReader } from 'reactjs-pdf-reader';
import { ToWords } from 'to-words';
import moment from 'moment';
import {
    renderInvoiceNumber,
    renderCustomerDetails,
    renderProductDetails,
    renderGSTDetails,
    renderOtherDetails,
    renderReceiverDetails
} from "./Components";
import { isMobile } from "react-device-detect";

const { Title } = Typography;
const toWords = new ToWords();

const handleInvoiceSubmit = (data, state, setState) => {
    let invoiceData = data || {};
    let customer_details = {
        customer_id: invoiceData.customer_id, customer_address: service.titleCase(invoiceData.customer_address), customer_email: invoiceData.customer_email,
        customer_gst_number: invoiceData.customer_gst_number, customer_msme_number: invoiceData.customer_msme_number, customer_order_number: invoiceData.customer_order_number && invoiceData.customer_order_number.toUpperCase(),
        customer_phone: invoiceData.customer_phone, place_of_supply: invoiceData.place_of_supply, state_code: invoiceData.state_code
    }

    let customerData = state.customerList || [];
    customerData.forEach(m => {
        if (m._id == customer_details.customer_id) {
            customer_details.customer_name = m.customer_name.toUpperCase();
        }
    });

    let products = invoiceData.products || [], productData = state.productList || [];

    let product_details = products.map(m => {
        let obj = {};
        productData.forEach(n => {
            if (m.product_id == n._id) {
                obj = Object.assign(n, m)
            }
        });

        obj.product_name = `${obj.product_length} X ${obj.product_width} X ${obj.product_height}`;
        obj.product_total_price = (Number(obj.quantity) * Number(obj.product_price)).toFixed(2);
        return obj;
    })

    let receiver_details = {
        receiver_name: invoiceData.receiver_name && invoiceData.receiver_name.toUpperCase(), receiver_phone: invoiceData.receiver_phone && invoiceData.receiver_phone.toUpperCase(),
        receiver_dl: invoiceData.receiver_dl && invoiceData.receiver_dl.toUpperCase(), receiver_rc_number: invoiceData.receiver_rc_number && invoiceData.receiver_rc_number.toUpperCase()
    }

    let gst_details = { cgst: invoiceData.cgst, sgst: invoiceData.sgst };

    let material_details = (invoiceData.material_details || []).reduce((acc, item) => {
        acc[item] = true
        return acc;
    }, {});

    let summary_details = {};
    let total = 0;
    product_details.forEach(item => {
        total += Number(item.product_total_price);
        summary_details.gross_total = total;
    })

    if (summary_details["gross_total"] && summary_details["gross_total"] != "NaN") {
        summary_details["cgst_value"] = parseFloat(((summary_details["gross_total"] * Number(gst_details.cgst)) / 100).toFixed(2));
        summary_details["sgst_value"] = parseFloat(((summary_details["gross_total"] * Number(gst_details.sgst)) / 100).toFixed(2));
        summary_details["total_amount"] = Math.ceil(Number(summary_details["cgst_value"]) + Number(summary_details["sgst_value"]) + Number(summary_details["gross_total"]));
        summary_details["amount_in_words"] = toWords.convert(summary_details["total_amount"], { currency: true });
    }

    let pdfInfo = {invoice_date: moment(invoiceData.invoice_date, 'L'), invoice_number: invoiceData.invoice_number, current_date: invoiceData.current_date, status: "Draft", customer_details, product_details, receiver_details, gst_details, material_details, summary_details };
    console.log(pdfInfo, "pdfinfo")
    setState(() => ({
        ...state,
        modalOpen: true,
        pdfInfo,
        openPdf: true
    }))
}

const renderInvoiceForm = (form, state, setState) => {
    return (
        <div className="invoice-form" style={{ border: isMobile ? "unset" : "1px solid #ccc", borderRadius: "5px", padding: isMobile ? "0px 20px" : "27px 45px" }}>
            <Title level={4} style={{ display: "flex", justifyContent: "center", marginBottom: "25px" }}>INVOICE FORM</Title>
            <Form name="invoice" form={form}
                layout="horizontal"
                scrollToFirstError
                onFinish={(data) => { handleInvoiceSubmit(data, state, setState) }}
                initialValues={{
                    cgst: 6,
                    sgst: 6
                }}
                autoComplete="off">
                <Row>
                    <Col span={24}>
                        {renderInvoiceNumber()}
                    </Col>
                </Row>
                <Divider orientation="left" >Customer Details</Divider>
                <Row>
                    <Col span={24}>
                        {renderCustomerDetails(form, state, setState)}
                    </Col>
                </Row>
                <Divider orientation="left" >Product Details</Divider>
                <Row>
                    <Col span={24}>
                        {renderProductDetails(form, state, setState)}
                    </Col>
                </Row>
                <Divider orientation="left" >GST Details</Divider>
                <Row>
                    <Col span={24}>
                        {renderGSTDetails(form, state, setState)}
                    </Col>
                </Row>
                <Divider orientation="left" >Other Details</Divider>
                <Row>
                    <Col span={24}>
                        {renderOtherDetails(form, state, setState)}
                    </Col>
                </Row>
                <Divider orientation="left" >Receiver Details</Divider>
                <Row>
                    <Col span={24}>
                        {renderReceiverDetails(form, state, setState)}
                    </Col>
                </Row>
                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Col span={4}>
                        <Form.Item style={style.PopUpAddButton}>
                            <Button style={{ ...style.AddCustomerButton }} htmlType="submit" >{"Preview"}</Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </div>
    )
}

const InvoiceComponent = (props) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    const current_date = `${dd}/${mm}/${yyyy}`

    const [state, setState] = useState({
        customerList: [],
        productList: [], 
        refresh: true
    });

    const [invoiceNumber, setInvoiceNumber] = useState();
    const [refresh, setRefresh] = useState(true);
    const [form] = Form.useForm();

    useEffect(() => {
        service.GET("get_customer_data", [], {}).then(res => {
            setState(() => ({
                ...state,
                customerList: res && res.length ? res : []
            }))
        }).catch(err => {
            console.log(err)
        });
        service.DISTINCT("sales_report_index", { column: "invoice_number", value: "_id", orderby: "desc" }).then((data, err) => {
            if (data) {
                data = service.sortByKey(data, "invoice_number", "desc");
                setInvoiceNumber(data[0] && data[0]["invoice_number"] && Number(data[0]["invoice_number"]) + 1);
                form.setFieldsValue({
                    invoice_number: data[0] && data[0]["invoice_number"] && Number(data[0]["invoice_number"]) + 1 || null,
                    invoice_date: moment(current_date, 'DD/MM/YYYY')
                })
            }
        });

    }, [state.refresh]);

    const handleModalClose = () => {
        setState(() => ({
            ...state,
            openPdf: false,
            modalOpen: false
        }))
    }

    return (
        <div className="invoice-component-container" style={{ width: "100%" }}>
            {renderInvoiceForm(form, state, setState)}

            {state.openPdf ? <PDFDownloadLink document={<PdfDownLoader {...state} />} fileName={`${state.invoice_number}_${current_date}.pdf`} >
                {({ blob, url, loading, error }) => {
                    if (blob && url) {
                        return (<ModalComponent visible={state.modalOpen} onClose={handleModalClose} style={{ top: "10px" }} children={
                            <div className="pdf-container" >
                                <div style={{ height: "430px", overflow: "auto" }}>
                                    <PDFReader url={url} width={850} onDocumentComplete={pages => {
                                        setState(() => ({
                                            ...state,
                                            blobUrl: url,
                                            blob: blob
                                        }))
                                    }} />
                                </div>

                                <div className="pdf-options" style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                                    <Button style={{ ...style.AddCustomerButton, margin: "5px" }} onClick={e => handleCancel(state, setState, form)} >{"Cancel"}</Button>
                                    <Button style={{ ...style.AddCustomerButton, margin: "5px" }} onClick={e => handleEdit(state, setState)} >{"Edit"}</Button>
                                    <Button style={{ ...style.AddCustomerButton, margin: "5px" }} onClick={e => handleDownload(state, setState, form)} >{"Download"}</Button>
                                </div>
                            </div>
                        } title={"Invoice Preview"} />)
                    } else {
                        return (
                            <div className="loading" style={{ ...style.printLoader }}>
                                <img src={loader} style={{ width: "45px", height: "45px" }} />
                            </div>
                        )
                    }
                }
                }
            </PDFDownloadLink> : null}
        </div>
    )
}

const handleDownload = (state, setState, form) => {
    setState(() => ({
        ...state,
        isLoader: true,
    }));

    service.UPDATE("add_sales_data", state.pdfInfo).then(res => {
        if (res)
            setState(() => ({
                ...state,
                openPdf: false,
                modalOpen: false,
                isLoader: false,
                refresh: !state.refresh
            }));
        form.resetFields();
        const fileURL = URL.createObjectURL(state.blob);
        const pdfWindow = window.open();
        pdfWindow.location.href = fileURL;
    }).catch(err => {
        console.log(err)
    });

}
const handleCancel = (state, setState, form) => {
    setState(() => ({
        ...state,
        openPdf: false,
        modalOpen: false
    }));
    form.resetFields()
}

const handleEdit = (state, setState) => {
    setState(() => ({
        ...state,
        openPdf: false,
        modalOpen: false,
    }));
}

const mapStateToProps = state => {
    return {
        // productTableData: state.productTableData
    }
};

const mapDispatchToProps = dispatch => ({
    // setProductTableData: data => dispatch(service.fetchProductData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceComponent);